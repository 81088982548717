import { Button, Checkbox, DatePicker, Dropdown, Menu, Popover, Space, Tooltip } from 'antd'
import styles from './AdminDashboard.module.css'
import { ReloadOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchAllOrderAmountTrends,
	fetchAllOrderDeliveryPartnerTrends,
	fetchAllOrderSourceTrends,
	fetchAllOrderTrends,
	fetchCustomerTrends,
	fetchECommerceAccountTrends,
	fetchECommerceAllAccountTrends,
	fetchECommerceCumulativeActiveUserTrend,
	fetchECommerceLiveCumulativeTrend,
	fetchECommerceSalesOrdersMonthlyTrend,
	fetchEnterpriseAccountTrends,
	fetchLocationTrends,
	fetchProductTrends,
	fetchPurchaseOrderAmountTrends,
	fetchPurchaseOrderTrends,
	fetchRegisteredTrends,
	fetchSignUpTrends
} from '../../../store/dashboard/actions'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { convertEnumToString, isEmptyObject } from '../../../utils'
import { Users } from '../../../services/api/users'
import { linearGradientDef } from '@nivo/core'
import { PartnerType, industries as industryNames, ecommerceSubscriptionEvent, industryGroup, orderSources, SubscriptionStatus, userLogEvent } from '../../../utils/constants'
import HeaderTab from '../../header-tab'
import { Dashboard } from '../../../services/api/dashboard'

const chartPeriods = [
	{
		label: 'Daily',
		value: 'daily'
	},
	{
		label: 'Weekly',
		value: 'weekly'
	},
	{
		label: 'Monthly',
		value: 'monthly'
	},
	{
		label: 'Yearly',
		value: 'annually'
	}
]

const countPeriods = [
	{
		label: 'This Week',
		value: 'weekly'
	},
	{
		label: 'This Month',
		value: 'monthly'
	},
	{
		label: 'This Year',
		value: 'annually'
	},
	{
		label: 'Lifetime',
		value: 'lifetime'
	},
	{
		label: 'Custom',
		value: 'custom'
	}
]

const industryGroupTabs = [
	{
		label: 'E-Commerce',
		value: industryGroup.E_COMMERCE
	},
	{
		label: 'Enterprise',
		value: industryGroup.ENTERPRISE
	}
]

const tabs = [
	{
		title: 'Overview',
		value: 'overview'
	},
	{
		title: 'Accounts',
		value: 'accounts'
	},
	{
		title: 'Users',
		value: 'users'
	},
	{
		title: 'Orders',
		value: 'orders'
	}
]

const OverviewType = {
	ACCOUNTS: 'accounts',
	USERS: 'users',
	ORDERS: 'orders',
	COURIERS: 'couriers',
	SOURCES: 'sources'
}

const overviewTypes = [
	{
		label: 'Accounts',
		value: OverviewType.ACCOUNTS
	},
	{
		label: 'Users',
		value: OverviewType.USERS
	},
	{
		label: 'Orders',
		value: OverviewType.ORDERS
	},
	{
		label: 'Dispatched Sales Orders to Couriers',
		value: OverviewType.COURIERS
	},
	{
		label: 'Order Sources',
		value: OverviewType.SOURCES
	}
]

const ecommerceLiveChartStatusFilterOptions = [
	{
		label: 'Active',
		value: SubscriptionStatus.ACTIVE
	},
	{
		label: 'Incomplete',
		value: SubscriptionStatus.INCOMPLETE
	}
]

const AdminDashboard = () => {
	const dispatch = useDispatch()
	const {
		signUpCountTrend,
		enterpriseLiveAccountCountTrend,
		enterpriseChurnAccountCountTrend,
		ecommerceTrialAccountCountTrend,
		ecommerceLiveAccountCountTrend,
		ecommerceChurnedAccountCountTrend,
		ecommerceAllAccountCountTrend,
		registeredCountTrend,
		productCountTrend,
		customerCountTrend,
		locationCountTrend,
		allOrderCountTrend,
		allOrderAmountTrend,
		allOrderSourceTrend,
		allOrderDeliveryPartnerTrend,
		purchaseOrderCountTrend,
		purchaseOrderAmountTrend,
		liveAccountCumulativeTrend,
		activeUserCumulativeTrend,
		salesOrdersProcessedMonthlyTrend,
		salesOrdersValueMonthlyTrend
	} = useSelector(state => state.dashboardReducer)
	const { permissions } = useSelector(state => state.authReducer)
	const [selectedIndustryGroup, setSelectedIndustryGroup] = useState(industryGroup.E_COMMERCE)
	const [currentTab, setCurrentTab] = useState(tabs[0].value)
	const [industries, setIndustries] = useState([industryNames.E_COMMERCE])
	const [countPeriod, setCountPeriod] = useState(countPeriods[1])
	const [chartPeriod, setChartPeriod] = useState(chartPeriods[2])
	const [selectedIndustries, setSelectedIndustries] = useState({})
	const [isAllSelected, setIsAllSelected] = useState(true)
	const [ecommerceLiveChartStatusFilter, setEcommerceLiveChartStatusFilter] = useState([SubscriptionStatus.ACTIVE, SubscriptionStatus.INCOMPLETE])
	const showCountsTrend = countPeriod.value !== countPeriods[3].value && countPeriod.value !== countPeriods[4].value

	useEffect(() => {
		fetchIndustries()
	}, [selectedIndustryGroup])

	const fetchIndustries = async () => {
		try {
			let result = []
			if (selectedIndustryGroup === industryGroup.E_COMMERCE) {
				result.push(industryGroup.E_COMMERCE)
				setSelectedIndustries({
					[industryGroup.E_COMMERCE]: true
				})
			} else {
				const { data } = await Users.fetchIndustries()
				if (data) {
					const filteredData = data.filter(industry => industry.value !== industryGroup.E_COMMERCE)
					result.push(...filteredData)
				}
				const selectedIndustries = {}
				result.forEach(industry => {
					selectedIndustries[industry.value] = true
				})
				setSelectedIndustries(selectedIndustries)
			}
			setIndustries(result)
		} catch (e) {
			console.error(e)
		}
	}

	const renderEcommerceLiveAccountChartFilter = () => {
		return (
			<Dropdown
				trigger={['click']}
				overlay={() => {
					return (
						<Menu>
							<Checkbox.Group
								value={ecommerceLiveChartStatusFilter}
								className={styles.chartFilterCheckboxOptions}
								onChange={e => setEcommerceLiveChartStatusFilter(e)}
							>
								{
									ecommerceLiveChartStatusFilterOptions.map(option => {
										return (
											<Checkbox
												key={option.value}
												className={styles.chartFilterOptionWrapper}
												value={option.value}
											>
												{option.label}
											</Checkbox>
										)
									})
								}
							</Checkbox.Group>
						</Menu>
					)
				}}
			>
				<div
					className={styles.chartFilterButton}
				>
					Subscription Status
					<DownOutlined style={{ marginLeft: 12 }} />
				</div>
			</Dropdown>
		)
	}

	if (permissions.viewDashboardAnalytics === false) {
		return (
			<div className={styles.container}>
				<div className={styles.headerContainer}>
					<div className={styles.headerRow}>
						<h2 className={styles.heading}>Not Authorized.</h2>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<div className={styles.headerRow}>
					<h2>Dashboard</h2>
					<div className={styles.industryTabsContainer}>
						{
							industryGroupTabs.map(group => {
								return (
									<HeaderTab
										key={group.value}
										title={group.label}
										isSelected={() => selectedIndustryGroup === group.value }
										onClick={() => setSelectedIndustryGroup(group.value) }
									/>
								)
							})
						}
					</div>
					<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: 24, whiteSpace: 'nowrap' }}>
						{
							industries && selectedIndustryGroup === industryGroup.ENTERPRISE &&
							<Popover
								trigger={['click']}
								content={() => {
									return (
										<div className={styles.checkboxGroup}>
											<div
												key='select-all'
											>
												<Checkbox
													onChange={e => {
														setIsAllSelected(e.target.checked)
														const selectedIndustries = {}
														industries.forEach(industry => {
															selectedIndustries[industry.value] = e.target.checked
														})
														setSelectedIndustries(selectedIndustries)
													}}
													checked={isAllSelected}
												>
													Select All
												</Checkbox>
											</div>
											{
												industries.map(industry => {
													return (
														<div
															key={industry.value}
														>
															<Checkbox
																onChange={e => {
																	const checked = e.target.checked
																	setSelectedIndustries({
																		...selectedIndustries,
																		[industry.value]: checked
																	})
																	if (checked === false) {
																		setIsAllSelected(false)
																	}
																}}
																checked={selectedIndustries[industry.value]}
															>
																{industry.label}
															</Checkbox>
														</div>
													)
												})
											}
										</div>
									)
								}}
							>
								<div
									className={styles.periodSelected}
									style={{ color: '#00171D', borderColor: '#00171D' }}
								>
									Industry Filter
									<DownOutlined style={{ marginLeft: 12 }} />
								</div>
							</Popover>
						}
						{
							currentTab === 'overview' ?
								<Dropdown
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													countPeriods.map(periodItem => {
														return (
															<Menu.Item
																key={periodItem.value}
																onClick={() => {
																	if (periodItem.value !== countPeriod.value) {
																		setCountPeriod(periodItem)
																	}
																}}
															>
																{periodItem.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.periodSelected}
									>
										{countPeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown> :
								<Dropdown
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													chartPeriods.map(periodItem => {
														return (
															<Menu.Item
																key={periodItem.value}
																onClick={() => {
																	if (periodItem.value !== chartPeriod.value) {
																		setChartPeriod(periodItem)
																	}
																}}
															>
																{periodItem.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.periodSelected}
									>
										{chartPeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
						}
					</div>
				</div>
				<div className={styles.tabsAndButtonContainer}>
					<div className={styles.tabContainer}>
						{
							tabs.map(tab => {
								return (
									<HeaderTab
										key={tab.value}
										title={tab.title}
										isSelected={() => currentTab === tab.value}
										onClick={() => setCurrentTab(tab.value)}
									/>
								)
							})
						}
					</div>
				</div>
			</div>
			{
				currentTab === 'overview' &&
				<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
					{
						selectedIndustryGroup === industryGroup.E_COMMERCE &&
						<div className={styles.overviewLineChartSection}>
							<Chart
								title='Live Accounts'
								tooltip={'Cumulative results of live accounts on a monthly basis'}
								legendText='Count'
								color='#288EA5'
								backgroundColor='#FFF'
								type='line'
								total={liveAccountCumulativeTrend.total}
								data={liveAccountCumulativeTrend.trend || []}
								fetchData={async () => {
									await dispatch(fetchECommerceLiveCumulativeTrend())
								}}
								tooltipYLabel='Count'
								chartPeriod={chartPeriods[2]}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								minimal={true}
							/>
							<Chart
								title='Active Users'
								tooltip={'Cumulative results of active users on a monthly basis'}
								legendText='Count'
								color='#288EA5'
								backgroundColor='#FFF'
								type='line'
								total={activeUserCumulativeTrend.total}
								data={activeUserCumulativeTrend.trend || []}
								fetchData={async () => {
									await dispatch(fetchECommerceCumulativeActiveUserTrend())
								}}
								tooltipYLabel='Count'
								chartPeriod={chartPeriods[2]}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								minimal={true}
							/>
							<Chart
								title='Sales Order Processed'
								tooltip={'The total number of processed sales orders on a monthly basis'}
								legendText='Count'
								color='#288EA5'
								backgroundColor='#FFF'
								type='line'
								total={salesOrdersProcessedMonthlyTrend.total}
								data={salesOrdersProcessedMonthlyTrend.trend || []}
								fetchData={async () => {
									await dispatch(fetchECommerceSalesOrdersMonthlyTrend())
								}}
								tooltipYLabel='Count'
								chartPeriod={chartPeriods[2]}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								minimal={true}
							/>
							<Chart
								title='Sales Order Value'
								tooltip={'The total value of processed sales orders on a monthly basis'}
								legendText='Value'
								color='#288EA5'
								backgroundColor='#FFF'
								type='line'
								total={salesOrdersValueMonthlyTrend.total}
								data={salesOrdersValueMonthlyTrend.trend || []}
								tooltipYLabel='Value'
								chartPeriod={chartPeriods[2]}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								minimal={true}
							/>
						</div>
					}
					<Counts
						industries={industries}
						fetchData={async ({
							fromDate,
							toDate,
							overviewType,
							industries
						}) => {
							return await Dashboard.fetchOverviewCounts({ fromDate, toDate, overviewType, industries, getPreviousPeriod: showCountsTrend })
						}}
						overviewType={overviewTypes[0]}
						period={countPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE ?
								'Number of accounts categorized by various criteria based on the E-Commerce industry and time period.' :
								'Number of accounts categorized by various criteria based on the selected industry and time period.'
						}
					/>
					<Counts
						industries={industries}
						fetchData={async ({
							fromDate,
							toDate,
							overviewType,
							industries
						}) => {
							return await Dashboard.fetchOverviewCounts({ fromDate, toDate, overviewType, industries, getPreviousPeriod: showCountsTrend })
						}}
						overviewType={overviewTypes[2]}
						period={countPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE ?
								'Number of orders categorized by various criteria based on the E-Commerce industry and time period.' :
								'Number of orders categorized by various criteria based on the selected industry and time period.'
						}
					/>
					{
						selectedIndustryGroup === industryGroup.E_COMMERCE &&
						<>
							<Counts
								industries={industries}
								fetchData={async ({
									fromDate,
									toDate,
									overviewType,
									industries
								}) => {
									return await Dashboard.fetchOverviewCounts({ fromDate, toDate, overviewType, industries, getPreviousPeriod: showCountsTrend })
								}}
								overviewType={overviewTypes[3]}
								period={countPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								tooltip='Number of dispatched sales orders to couriers categorized by various couriers based on the E-Commerce industry and time period.'
							/>
							<Counts
								industries={industries}
								fetchData={async ({
									fromDate,
									toDate,
									overviewType,
									industries
								}) => {
									return await Dashboard.fetchOverviewCounts({ fromDate, toDate, overviewType, industries, getPreviousPeriod: showCountsTrend })
								}}
								overviewType={overviewTypes[4]}
								period={countPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								tooltip='Number of orders categorized by various sources for the E-Commerce industry and time period.'
							/>
						</>
					}
					<Counts
						industries={industries}
						fetchData={async ({
							fromDate,
							toDate,
							overviewType,
							industries
						}) => {
							return await Dashboard.fetchOverviewCounts({ fromDate, toDate, overviewType, industries, getPreviousPeriod: showCountsTrend })
						}}
						overviewType={overviewTypes[1]}
						period={countPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE ?
								'Number of users categorized by various criteria based on the E-Commerce industry and time period.' :
								'Number of users categorized by various criteria based on the selected industry and time period.'
						}
					/>
				</div>
			}
			{
				currentTab === 'accounts' &&
				<div className={styles.data}>
					<Chart
						title='Number of Sign Ups'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE ?
								'Total number of signup accounts in the E-Commerce industry in a time period' :
								'Total number of signup accounts based on the selected industry and time period'
						}
						legendText='Total Sign Ups'
						color='#306977'
						total={signUpCountTrend.total}
						data={signUpCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchSignUpTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Sign Ups'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					{
						selectedIndustryGroup === industryGroup.E_COMMERCE &&
						<>
							<Chart
								title='Trial Accounts'
								tooltip='Total number of trial accounts in the E-Commerce industry in a time period'
								legendText='Total Trial Accounts'
								color='#48A3B8'
								total={ecommerceTrialAccountCountTrend.total}
								data={ecommerceTrialAccountCountTrend.trend || []}
								fetchData={async ({ interval, currentRange }) => {
									await dispatch(fetchECommerceAccountTrends({ interval, currentRange, subscriptionEvent: ecommerceSubscriptionEvent.TRIALING }))
								}}
								tooltipYLabel='Trial Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
							<Chart
								title='Live Accounts'
								tooltip='Total number of E-Commerce live accounts based on the account status and time period'
								legendText='Total Live Accounts'
								color='#22E6C2'
								total={ecommerceLiveAccountCountTrend.total}
								data={ecommerceLiveAccountCountTrend.trend || []}
								fetchData={async ({ interval, currentRange }) => {
									await dispatch(fetchECommerceAccountTrends({
										interval,
										currentRange,
										subscriptionStatus: ecommerceLiveChartStatusFilter,
										subscriptionEvent: ecommerceSubscriptionEvent.LIVE
									}))
								}}
								tooltipYLabel='Live Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
								chartFilter={renderEcommerceLiveAccountChartFilter()}
								selectedChartFilter={ecommerceLiveChartStatusFilter}
							/>
							<Chart
								title='Churned Accounts'
								tooltip='Total number of E-Commerce churned accounts based on the churned type and time period, excluding trial-ended and subscription-cancelled accounts.'
								legendText='Total Churned Accounts'
								color='#E65050'
								total={ecommerceChurnedAccountCountTrend.total}
								data={ecommerceChurnedAccountCountTrend.trend || []}
								fetchData={async ({ interval, currentRange }) => {
									await dispatch(fetchECommerceAccountTrends({
										interval,
										currentRange,
										subscriptionStatus: [SubscriptionStatus.PAST_DUE],
										subscriptionEvent: ecommerceSubscriptionEvent.CHURNED
									}))
								}}
								tooltipYLabel='Churned Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
							<Chart
								title='Sign Up vs Trial vs Live vs Churned Accounts'
								tooltip='Total number of sign up, trial, live, and churned accounts based on the selected time period'
								keys={['SIGN_UP', ...Object.keys(ecommerceSubscriptionEvent)]}
								data={ecommerceAllAccountCountTrend.trend || []}
								legend
								colors={
									[
										'#306977',
										'#48A3B8',
										'#22E6C2',
										'#E65050'
									]
								}
								fetchData={async ({ interval, currentRange }) => {
									await dispatch(fetchECommerceAllAccountTrends({ interval, currentRange }))
								}}
								tooltipYLabelDynamic={true}
								tooltipYLabel=' Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
						</>
					}
					{
						selectedIndustryGroup === industryGroup.ENTERPRISE &&
						<>
							<Chart
								title='Live Accounts'
								tooltip='Total number of live accounts based on the selected industry and time period'
								legendText='Total Live Accounts'
								color='#22E6C2'
								total={enterpriseLiveAccountCountTrend.total}
								data={enterpriseLiveAccountCountTrend.trend || []}
								fetchData={async ({ interval, industries, currentRange }) => {
									await dispatch(fetchEnterpriseAccountTrends({ interval, industries, currentRange, event: userLogEvent.ENABLED }))
								}}
								tooltipYLabel='Live Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
							<Chart
								title='Churned Accounts'
								tooltip='Total number of churned accounts based on the selected industry and time period'
								legendText='Total Churned Accounts'
								color='#FF5959'
								total={enterpriseChurnAccountCountTrend.total}
								data={enterpriseChurnAccountCountTrend.trend || []}
								fetchData={async ({ interval, industries, currentRange }) => {
									await dispatch(fetchEnterpriseAccountTrends({ interval, industries, currentRange, event: userLogEvent.DISABLED }))
								}}
								tooltipYLabel='Churned Accounts'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
						</>
					}
				</div>
			}
			{
				currentTab === 'users' &&
				<div className={styles.data}>
					<Chart
						title='Registered Users'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of registered users based in the E-Commerce industry and selected time period'
								: 'Total number of registered users based on the selected industry and time period'
						}
						legendText='Total Application Users'
						color='#288EA5'
						total={registeredCountTrend.total}
						data={registeredCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchRegisteredTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Users'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					<Chart
						title='Registered End Customers'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of registered customers in the E-Commerce industry and selected time period'
								: 'Total number of registered customers of every company based on the selected industry and time period'
						}
						legendText='Total Customers'
						color='#288EA5'
						type='line'
						total={customerCountTrend.total}
						data={customerCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchCustomerTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Customers'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					<Chart
						title='Registered Locations'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of registered locations in the E-Commerce industry and selected time period'
								: 'Total number of registered locations based on the selected industry and time period'
						}
						legendText='Total Locations'
						color='#288EA5'
						total={locationCountTrend.total}
						data={locationCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchLocationTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Locations'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
				</div>
			}
			{
				currentTab === 'orders' &&
				<div className={styles.data}>
					<Chart
						title='Sales Orders Processed'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of processed sales orders based on the E-Commerce industry and time period'
								: 'Total number of processed sales orders based on the selected industry and time period'
						}
						legendText='Total Count'
						color='#288EA5'
						total={allOrderCountTrend.total}
						data={allOrderCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchAllOrderTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Orders'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					<Chart
						title='Sales Orders Value'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total value of the processed sales orders based on the E-Commerce industry and time period'
								: 'Total value of the processed sales orders based on the selected industry and time period'
						}
						legendText='Total Value'
						color='#288EA5'
						total={allOrderAmountTrend.total}
						showCurrency
						data={allOrderAmountTrend.trend || []}
						yIndex='amount'
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchAllOrderAmountTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Amount'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					<Chart
						title='Purchase Orders Processed'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of processed purchased orders based on the E-Commerce industry and time period'
								: 'Total number of processed purchased orders based on the selected industry and time period'
						}
						legendText='Total Count'
						color='#288EA5'
						total={purchaseOrderCountTrend.total}
						data={purchaseOrderCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchPurchaseOrderTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Purchase Orders'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					<Chart
						title='Purchase Orders Value'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total value of the processed purchase orders based on the E-Commerce industry and time period'
								: 'Total value of the processed purchase orders based on the selected industry and time period'
						}
						legendText='Total Value'
						color='#288EA5'
						total={purchaseOrderAmountTrend.total}
						showCurrency
						data={purchaseOrderAmountTrend.trend || []}
						yIndex='amount'
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchPurchaseOrderAmountTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='Amount'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
					{
						selectedIndustryGroup === industryGroup.E_COMMERCE &&
						<>
							<Chart
								title='Dispatched Sales Orders to Couriers'
								tooltip='Total dispatched orders to courier partners based on the E-Commerce industry and time period'
								legendText='Total Orders'
								keys={Object.keys(PartnerType)}
								total={allOrderDeliveryPartnerTrend.totalCount}
								data={allOrderDeliveryPartnerTrend.trend || []}
								legend
								colors={
									[
										'#5A3288',
										'#58A58A',
										'#D64E3F',
										'#288EA5',
										'#D93A34',
										'#4EACE8',
										'#E1733A'
									]
								}
								fetchData={async ({ interval, industries, currentRange }) => {
									await dispatch(fetchAllOrderDeliveryPartnerTrends({ interval, industries, currentRange }))
								}}
								tooltipYLabel='Orders'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
							<Chart
								title='E-Commerce Order Sources'
								tooltip='Total order count based on the order sources for the E-Commerce industry and time period'
								legendText='Total Orders'
								keys={Object.keys(orderSources)}
								total={allOrderSourceTrend.totalCount}
								data={allOrderSourceTrend.trend || []}
								legend
								colors={
									[
										'#3975EA',
										'#F3B842',
										'#288EA5',
										'#0F3E49',
										'#19B0DA',
										'#8075F9',
										'#C13ED2',
										'#25D366',
										'#00F2EA',
										'#FF6541'
									]
								}
								fetchData={async ({ interval, industries, currentRange }) => {
									await dispatch(fetchAllOrderSourceTrends({ interval, industries, currentRange }))
								}}
								tooltipYLabel='Orders'
								chartPeriod={chartPeriod}
								selectedIndustries={selectedIndustries}
								isAllSelected={isAllSelected}
							/>
						</>
					}
					<Chart
						title='Registered SKUs'
						tooltip={
							selectedIndustryGroup === industryGroup.E_COMMERCE
								? 'Total number of registered SKUs based on the E-Commerce industry and time period'
								: 'Total number of registered SKUs based on the selected industry and time period'
						}
						legendText='Total SKUs'
						color='#288EA5'
						type='line'
						total={productCountTrend.total}
						data={productCountTrend.trend || []}
						fetchData={async ({ interval, industries, currentRange }) => {
							await dispatch(fetchProductTrends({ interval, industries, currentRange }))
						}}
						tooltipYLabel='SKUs'
						chartPeriod={chartPeriod}
						selectedIndustries={selectedIndustries}
						isAllSelected={isAllSelected}
					/>
				</div>
			}
		</div>
	)
}

export default AdminDashboard

export const Counts = ({
	fetchData,
	overviewType,
	tooltip,
	period,
	selectedIndustries
}) => {
	const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').toDate())
	const [toDate, setToDate] = useState(moment().toDate())
	const [counts, setCounts] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!isEmptyObject(selectedIndustries)) {
			getCountData()
		}
	}, [
		fromDate,
		toDate,
		selectedIndustries,
		overviewType
	])

	useEffect(() => {
		const from = moment()
		const to = moment()
		switch (period.value) {
			case 'daily':
				from.subtract(1, 'day')
				to.endOf('day')
				break
			case 'weekly':
				from.startOf('week')
				to.startOf('week').add(6, 'days').endOf('day')
				break
			case 'monthly':
				from.startOf('month')
				to.endOf('month')
				break
			case 'annually':
				from.startOf('year')
				to.endOf('year')
				break
			case 'custom':
				from.startOf('day')
				to.endOf('day')
		}
		if (period.value === 'lifetime') {
			setFromDate(undefined)
			setToDate(undefined)
		} else {
			setFromDate(from.toDate())
			setToDate(to.toDate())
		}
	}, [period])

	const getCountData = async () => {
		try {
			if (!fetchData) {
				return
			}
			setIsLoading(true)
			const industries = []
			const isAllUnselected = Object.values(selectedIndustries).every(value => value === false)
			if (isAllUnselected) {
				Object.keys(selectedIndustries).forEach(industry => {
					industries.push(industry)
				})
			} else {
				Object.keys(selectedIndustries).forEach(industry => {
					if (selectedIndustries[industry]) {
						industries.push(industry)
					}
				})
			}
			const { data } = await fetchData({
				fromDate,
				toDate,
				overviewType: overviewType.value,
				industries
			})
			if (overviewType.value === OverviewType.SOURCES || overviewType.value === OverviewType.COURIERS) {
				setCounts(data.map(d => ({ ...d, label: convertEnumToString(d.label.toLowerCase()) })))
			} else {
				setCounts(data)
			}
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className={styles.chartContainer}>
			<div style={{ display: 'flex', width: '100%' }}>
				<div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<div className={styles.chartTitle}>{overviewType.label}</div>
						<Tooltip title={tooltip} placement='bottom'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
						<Button
							style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
							icon={<ReloadOutlined />}
							shape='circle'
							loading={isLoading}
							onClick={() => getCountData()}
						/>
					</div>
					{
						period.value !== 'lifetime' && period.value !== 'custom' ?
							<div className={styles.dateRange}>
								<div>
									{moment(fromDate).format('DD MMM, YYYY h:mm A')}
								</div>
							-
								<div>
									{moment(toDate).format('DD MMM, YYYY h:mm A')}
								</div>
							</div> :
							period.value === 'lifetime' ?
								<div className={styles.dateRange}>
							Lifetime
								</div> : null
					}
					{
						period.value === 'custom' &&
						<Space
							size='small'
							style={{ marginTop: 4 }}
						>
							<DatePicker
								dropdownClassName={styles.overlay}
								placeholder='From'
								value={fromDate ? moment(fromDate) : null}
								onChange={(date) => setFromDate(date?.startOf('day').toDate())}
							/>
							<div className={styles.dash} />
							<DatePicker
								dropdownClassName={styles.overlay}
								placeholder='To'
								value={toDate ? moment(toDate) : null}
								onChange={(date) => setToDate(date?.endOf('day').toDate())}
								disabledDate={current => current && current.valueOf() < moment(fromDate)}
							/>
						</Space>
					}
				</div>
			</div>
			<div className={styles.countsContainer}>
				{
					counts.map((count, index) => {
						const formattedValue = Intl.NumberFormat('en-US', {
							notation: 'compact',
							maximumFractionDigits: 1
						}).format(+count.value)
						const trendChange = +count.value - +count.previousPeriodValue
						const trendPercentage = count.showTrend
							? (trendChange / +count.previousPeriodValue) * 100
							: 0
						const formattedTrend = Intl.NumberFormat('en-US', {
							notation: 'compact',
							maximumFractionDigits: 1
						}).format(trendPercentage)
						return (
							<>
								<div key={count.label} className={styles.countContainer}>
									<div className={styles.countLabel}>{count.label}</div>
									<div className={styles.countValueContainer}>
										<div className={styles.countValue}>{formattedValue}</div>
										{
											count.showTrend ?
												<>
													{
														isNaN(trendPercentage) &&
													<>
														<svg className={styles.trendIcon}>
															<use href='/img/stable-trend.svg#icon' />
														</svg>
														<div className={styles.trendValue}>0%</div>
													</>
													}
													{
														!isNaN(trendPercentage) && trendChange > 0 &&
													<>
														<svg className={styles.trendIcon}>
															<use href='/img/upward-trend.svg#icon' />
														</svg>
														<div className={styles.trendValue}>+{formattedTrend}%</div>
													</>
													}
													{
														!isNaN(trendPercentage) && trendChange < 0 &&
													<>
														<svg className={styles.trendIcon}>
															<use href='/img/downward-trend.svg#icon' />
														</svg>
														<div className={styles.trendValue}>{formattedTrend}%</div>
													</>
													}
													{
														!isNaN(trendPercentage) && trendChange === 0 &&
													<>
														<svg className={styles.trendIcon}>
															<use href='/img/stable-trend.svg#icon' />
														</svg>
														<div className={styles.trendValue}>{formattedTrend}%</div>
													</>
													}
												</> :
												<>
													<div className={styles.trendValue}>--</div>
												</>
										}
									</div>
								</div>
								{
									overviewType === overviewTypes[0] && index === 0 &&
									<div className={styles.emptyCountContainer} />
								}
							</>
						)
					})
				}
			</div>
		</div>
	)
}

export const Chart = ({
	title,
	tooltip,
	legendText,
	fetchData,
	total = 0,
	data = [],
	color,
	backgroundColor,
	colors,
	type = 'bar',
	yIndex = 'count',
	keys,
	legend,
	tooltipYLabel,
	tooltipYLabelDynamic = false,
	showCurrency = false,
	chartPeriod,
	selectedIndustries,
	isAllSelected,
	chartFilter,
	selectedChartFilter,
	minimal = false
}) => {
	const [currentRange, setCurrentRange] = useState(1)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		getChartData()
	}, [
		chartPeriod,
		selectedIndustries,
		isAllSelected,
		currentRange,
		selectedChartFilter
	])

	const getChartData = async () => {
		if (!fetchData) {
			return
		}
		setIsLoading(true)
		const industries = []
		const isAllUnselected = Object.values(selectedIndustries).every(value => value === false)
		if (isAllUnselected) {
			Object.keys(selectedIndustries).forEach(industry => {
				industries.push(industry)
			})
		} else {
			Object.keys(selectedIndustries).forEach(industry => {
				if (selectedIndustries[industry]) {
					industries.push(industry)
				}
			})
		}
		await fetchData({ interval: chartPeriod.value, industries, currentRange })
		setIsLoading(false)
	}

	const getXAxisValue = (d) => {
		if (!d) {
			return ''
		}
		if (chartPeriod.value === 'daily') {
			return d.split('-')[2]
		} else if (chartPeriod.value === 'weekly') {
			return d.split('-')[1]
		} else if (chartPeriod.value === 'monthly') {
			return d.split('-')[1]
		} else {
			return d
		}
	}

	const tooltipXLabel = useMemo(() => {
		if (chartPeriod.value === 'daily') {
			return 'Day'
		} else if (chartPeriod.value === 'weekly') {
			return 'Week'
		} else if (chartPeriod.value === 'monthly') {
			return 'Month'
		} else {
			return 'Year'
		}
	}, [chartPeriod])

	const chartData = useMemo(() => {
		if (type === 'bar') {
			if (chartPeriod.value === 'weekly') {
				return data.map(dataItem => ({ ...dataItem, date: dataItem.week, value: dataItem[yIndex], color }))
			} else if (chartPeriod.value === 'monthly') {
				return data.map(dataItem => ({ ...dataItem, date: dataItem.month, value: dataItem[yIndex], color }))
			} else if (chartPeriod.value === 'annually') {
				return data.map(dataItem => ({ ...dataItem, date: dataItem.year, value: dataItem[yIndex], color }))
			} else {
				return data.map(dataItem => ({ ...dataItem, date: dataItem.day, value: dataItem[yIndex], color }))
			}
		} else {
			if (chartPeriod.value === 'weekly') {
				return [
					{
						id: 'trend',
						color,
						data: data.map(dataItem => ({ x: dataItem.week, y: dataItem[yIndex] }))
					}
				]
			} else if (chartPeriod.value === 'monthly') {
				return [
					{
						id: 'trend',
						color,
						data: data.map(dataItem => ({ x: dataItem.month, y: dataItem[yIndex] }))
					}
				]
			} else if (chartPeriod.value === 'annually') {
				return [
					{
						id: 'trend',
						color,
						data: data.map(dataItem => ({ x: dataItem.year, y: dataItem[yIndex] }))
					}
				]
			} else {
				return [
					{
						id: 'trend',
						color,
						data: data.map(dataItem => ({ x: dataItem.day, y: dataItem[yIndex] }))
					}
				]
			}
		}
	}, [data])

	const { fromDate, toDate } = useMemo(() => {
		const from = moment()
		const to = moment()
		if (chartPeriod.value === 'weekly' || chartPeriod.value === 'daily') {
			from.subtract(currentRange, 'month')
			to.subtract(currentRange - 1, 'month')
		} else if (chartPeriod.value === 'monthly') {
			from.subtract(currentRange, 'year')
			to.subtract(currentRange - 1, 'year')
		} else if (chartPeriod.value === 'annually') {
			from.subtract(currentRange * 12, 'year')
			to.subtract((currentRange - 1) * 12, 'year')
		}
		return { fromDate: from.startOf('day').toDate(), toDate: to.endOf('day').toDate() }
	}, [chartPeriod, currentRange])

	const formattedTotal = useMemo(() => {
		const formattedTotal = Intl.NumberFormat('en-US', {
			notation: 'compact',
			maximumFractionDigits: 1
		}).format(+total)
		return showCurrency ? `BDT ${formattedTotal}` : formattedTotal
	}, [total])

	return (
		<div className={styles.chartContainer} style={{ background: backgroundColor || '#FAFAFA' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<div>
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<div className={styles.chartTitle}>{title}</div>
						<Tooltip title={tooltip} placement='bottom'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
						<Button
							style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}
							icon={<ReloadOutlined />}
							loading={isLoading}
							onClick={() => getChartData()}
						/>
					</div>
					{
						!minimal &&
						<div className={styles.dateRange}>
							<div>
								{moment(fromDate).format('DD MMM, YYYY h:mm A')}
							</div>
							-
							<div>
								{moment(toDate).format('DD MMM, YYYY h:mm A')}
							</div>
						</div>
					}
				</div>
				{
					chartFilter
						? chartFilter
						: null
				}
			</div>
			<div style={{ height: 256, width: '100%', display: 'flex', alignItems: 'center' }}>
				{
					!minimal &&
					<Button
						style={{ marginRight: 12, border: 'none' }}
						icon={<LeftOutlined />}
						onClick={() => setCurrentRange(currentRange + 1)}
					/>
				}
				{
					type === 'bar' ?
						<ResponsiveBar
							data={chartData}
							keys={keys ? keys : ['value']}
							indexBy='date'
							colors={colors ? colors : () => color}
							groupMode='grouped'
							margin={{ top: 24, right: 0, bottom: 24, left: 70 }}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							enableLabel={false}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32,
								format: (d) => getXAxisValue(d)
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 10,
								tickRotation: 0,
								legendPosition: 'middle',
								legend: legendText ? `${legendText}: ${formattedTotal}` : '',
								legendOffset: -60,
								format: v => {
									return Intl.NumberFormat('en-US', {
										notation: 'compact',
										maximumFractionDigits: 1
									}).format(v)
								}
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
							tooltipLabel={(e) => convertEnumToString(e.id.toLowerCase())}
							legendLabel={(e) => convertEnumToString(e.id.toLowerCase())}
							legends={legend ?
								[
									{
										dataFrom: 'keys',
										anchor: 'top-right',
										direction: 'row',
										justify: false,
										translateX: 0,
										translateY: -24,
										itemsSpacing: 0,
										itemWidth: 90,
										itemHeight: 20,
										itemDirection: 'left-to-right',
										itemOpacity: 0.85,
										symbolSize: 10,
										symbolShape: 'circle',
										effects: [
											{
												on: 'hover',
												style: {
													itemOpacity: 1
												}
											}
										]
									}
								] : []}
							tooltip={(e) => {
								const formattedValue = Intl.NumberFormat('en-US', {
									notation: 'compact',
									maximumFractionDigits: 1
								}).format(+e.formattedValue)
								return (
									<div
										style={{
											background: 'black',
											color: 'white',
											padding: '4px 8px'
										}}
									>
										<div>{tooltipXLabel}: {e.indexValue}</div>
										<div>{tooltipYLabelDynamic ? `${e.label}${tooltipYLabel}` : tooltipYLabel || 'Y'}: {formattedValue}</div>
									</div>
								)
							}}
						/> :
						<ResponsiveLine
							data={chartData}
							colors={() => color}
							margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
							padding={0.3}
							axisTop={null}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32,
								format: (d) => getXAxisValue(d)
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 10,
								tickRotation: 0,
								legendPosition: 'middle',
								legend: `${legendText}: ${formattedTotal}`,
								legendOffset: -60,
								format: v => {
									return Intl.NumberFormat('en-US', {
										notation: 'compact',
										maximumFractionDigits: 1
									}).format(v)
								}
							}}
							pointSize={2}
							pointBorderWidth={4}
							pointColor={{ theme: 'background' }}
							pointBorderColor={{ from: 'serieColor' }}
							enableGridX={false}
							enableGridY={false}
							useMesh={true}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
							defs={[
								linearGradientDef('gradientA', [
									{ offset: 0, color: 'inherit' },
									{ offset: 100, color: 'inherit', opacity: 0 }
								])
							]}
							fill={[{ match: '*', id: 'gradientA' }]}
							enableArea={true}
							tooltip={({ point }) => {
								const formattedValue = Intl.NumberFormat('en-US', {
									notation: 'compact',
									maximumFractionDigits: 1
								}).format(+point.data.yFormatted)
								return (
									<div
										style={{
											background: 'black',
											color: 'white',
											padding: '4px 8px'
										}}
									>
										<div>{tooltipXLabel}: {point.data.xFormatted}</div>
										<div>{tooltipYLabel || 'Y'}: {formattedValue}</div>
									</div>
								)
							}}
						/>
				}
				{
					!minimal &&
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<RightOutlined />}
						disabled={currentRange === 1}
						onClick={() => {
							setCurrentRange(currentRange - 1)
						}}
					/>
				}
			</div>
		</div>
	)
}
