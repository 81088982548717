import styles from './TopSalesOrderLocations.module.css'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { ReloadOutlined, DownOutlined } from '@ant-design/icons'
import { Row, Col, Tooltip, Button, Dropdown, Menu, Space, DatePicker, Typography, Spin, notification } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import { SalesOrders } from '../../services/api/sales-orders'
import { getFormattedCurrency, downloadArrayOfObjectsAsCSV } from '../../utils'
import { orderStatuses, timeSpan } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { timePeriods } from '../pages/e-commerce-dashboard'
const { Text } = Typography

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm A'

const TopSalesOrderLocations = ({ masterFilterOption = {}, selectedLocationIds }) => {
	const { permissions } = useSelector(state => state.authReducer)
	const [isLoadingTopLocationsByCount, setIsLoadingTopLocationsByCount] = useState(false)
	const [isDownloadingTopLocationsByCount, setIsDownloadingTopLocationsByCount] = useState(false)
	const [topLocationsByCount, setTopLocationsByCount] = useState([])
	const [totalCount, setTotalCount] = useState(0)
	const [topLocationsByCountPeriodOption, setTopLocationsByCountPeriodOption] = useState(timePeriods[0])
	const [topLocationsByCountPeriodFromDate, setTopLocationsByCountPeriodFromDate] = useState(moment().startOf('day'))
	const [topLocationsByCountPeriodToDate, setTopLocationsByCountPeriodToDate] = useState(moment().endOf('day'))
	const [isLoadingTopLocationsByAmount, setIsLoadingTopLocationsByAmount] = useState(false)
	const [isDownloadingTopLocationsByAmount, setIsDownloadingTopLocationsByAmount] = useState(false)
	const [topLocationsByAmount, setTopLocationsByAmount] = useState([])
	const [totalAmount, setTotalAmount] = useState(0)
	const [topLocationsByAmountPeriodOption, setTopLocationsByAmountPeriodOption] = useState(timePeriods[0])
	const [topLocationsByAmountPeriodFromDate, setTopLocationsByAmountPeriodFromDate] = useState(moment().startOf('day'))
	const [topLocationsByAmountPeriodToDate, setTopLocationsByAmountPeriodToDate] = useState(moment().endOf('day'))

	useEffect(() => {
		getTopLocationsByCount()
	}, [selectedLocationIds, topLocationsByCountPeriodFromDate, topLocationsByCountPeriodToDate])

	useEffect(() => {
		getTopLocationsByAmount()
	}, [selectedLocationIds, topLocationsByAmountPeriodFromDate, topLocationsByAmountPeriodToDate])

	useEffect(() => {
		if (masterFilterOption?.value) {
			const periodOption = timePeriods.find(option => option.key === masterFilterOption.value)
			if (periodOption.key !== topLocationsByCountPeriodOption.key) {
				setTopLocationsByCountPeriodOption(periodOption)
				if (periodOption.key !== 'custom') {
					const { fromDate, toDate } = getDateRange(periodOption)
					setTopLocationsByCountPeriodFromDate(fromDate)
					setTopLocationsByCountPeriodToDate(toDate)
				}
			}

			if (periodOption.key !== topLocationsByAmountPeriodOption.key) {
				setTopLocationsByAmountPeriodOption(periodOption)
				if (periodOption.key !== 'custom') {
					const { fromDate, toDate } = getDateRange(periodOption)
					setTopLocationsByAmountPeriodFromDate(fromDate)
					setTopLocationsByAmountPeriodToDate(toDate)
				}
			}
		}
	}, [masterFilterOption])

	const getDateRange = (period) => {
		switch (period.key) {
			case 'weekly':
			case timeSpan.week: {
				const start = moment().subtract(7, 'days').startOf('day')
				const end = moment().endOf('day')
				return { fromDate: start, toDate: end }
			}
			case timeSpan.currentWeek: {
				const start = moment().startOf('week')
				const end = moment().endOf('week')
				return { fromDate: start, toDate: end }
			}
			case 'monthly':
			case timeSpan.month: {
				const start = moment().subtract(30, 'days').startOf('day')
				const end = moment().endOf('day')
				return { fromDate: start, toDate: end }
			}
			case timeSpan.currentMonth: {
				const start = moment().startOf('month')
				const end = moment().endOf('month')
				return { fromDate: start, toDate: end }
			}
			case timeSpan.year: {
				const start = moment().startOf('year')
				const end = moment().endOf('year')
				return { fromDate: start, toDate: end }
			}
			case timeSpan.lifetime: {
				return { fromDate: null, toDate: null }
			}
			case timeSpan.custom:
				return { fromDate: null, toDate: null }
			case timeSpan.yesterday: {
				const start = moment().subtract(1, 'days').startOf('day')
				const end = moment().subtract(1, 'days').endOf('day')
				return { fromDate: start, toDate: end }
			}
			case 'daily':
			case timeSpan.today:
			default: {
				const start = moment().startOf('day')
				const end = moment().endOf('day')
				return { fromDate: start, toDate: end }
			}
		}
	}

	const getAxisBottomIntegerTickValues = (startValue = 0, endValue = 0, maxNumberOfTicks = 1) => {
		const tickValues = [startValue]
		const valueDiff = endValue - startValue
		const numberOfTicks = valueDiff + 1 < maxNumberOfTicks ? valueDiff + 1 : maxNumberOfTicks
		const step = Math.floor(valueDiff / (numberOfTicks - 1))
		let nextValue = startValue + step
		for (let i = 1; i < numberOfTicks; i++) {
			if (i >= numberOfTicks - 1 && endValue !== tickValues[tickValues.length - 1]) {
				tickValues.push(endValue)
				continue
			}
			tickValues.push(nextValue)
			nextValue += step
		}
		return tickValues
	}

	const getTopLocationsByCount = async () => {
		setIsLoadingTopLocationsByCount(true)
		try {
			const { data } = await SalesOrders.districtsBySalesOrders({
				status: orderStatuses.SALES_ORDER_APPROVED_STATUS,
				sortBy: 'count',
				sortOrder: 'desc',
				maxRows: 10,
				fromDate: topLocationsByCountPeriodFromDate?.toDate() || undefined,
				toDate: topLocationsByCountPeriodToDate?.toDate() || undefined,
				locationIds: selectedLocationIds
			})
			setTopLocationsByCount(data.results?.reverse() || [])
			setTotalCount(data?.totalCount || 0)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Top Sales Order Locations by Count',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingTopLocationsByCount(false)
		}
	}

	const downloadTopLocationsByCountCSV = async () => {
		if (!permissions.exportAnalytics) {
			return
		}
		if (isDownloadingTopLocationsByCount) {
			return
		}
		setIsDownloadingTopLocationsByCount(true)
		const sortedTopLocationsByCount = topLocationsByCount.sort((a, b) => b.count - a.count)
		downloadArrayOfObjectsAsCSV(sortedTopLocationsByCount, 'top_locations_by_order_count', {
			headers: [
				{ key: 'district', name: 'District Name' },
				{ key: 'count', name: 'Total Order Count' }
			]
		})
		setIsDownloadingTopLocationsByCount(false)
	}

	const renderTopLocationsByCountPeriodDateFilter = () => {
		return (
			<div style={{ display: 'flex', marginLeft: 'auto' }}>
				<Space size='small'>
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='From'
						value={topLocationsByCountPeriodFromDate || null}
						onChange={fromDate => {
							const fromDateStart = fromDate || null
							if (fromDateStart?.format(DATE_TIME_FORMAT) === topLocationsByCountPeriodFromDate?.format(DATE_TIME_FORMAT)) {
								return
							}
							setTopLocationsByCountPeriodFromDate(fromDateStart)
						}}
						disabledDate={current => current && topLocationsByCountPeriodToDate && current.valueOf() > topLocationsByCountPeriodToDate.valueOf()}
						showTime={{
							use12Hours: true
						}}
						format='YYYY-MM-DD h:mm A'
					/>
					<div className={styles.dash} />
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='To'
						value={topLocationsByCountPeriodToDate || null}
						onChange={toDate => {
							const toDateEnd = toDate || null
							if (toDateEnd?.format(DATE_TIME_FORMAT) === topLocationsByCountPeriodToDate?.format(DATE_TIME_FORMAT)) {
								return
							}
							setTopLocationsByCountPeriodToDate(toDateEnd)
						}}
						disabledDate={current => current && topLocationsByCountPeriodFromDate && current.valueOf() < topLocationsByCountPeriodFromDate.valueOf()}
						showTime={{
							use12Hours: true
						}}
						format='YYYY-MM-DD h:mm A'
					/>
				</Space>
			</div>
		)
	}

	const renderTopLocationsByCount = () => {
		return (
			<div>
				<div className={styles.graphContainer}>
					<div className={styles.tabInGraphContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
                                Order by Top 10 Locations
								<Tooltip title='Top 10 locations based on the total number of Approved order counts on the selected time period' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								shape='circle'
								onClick={() => getTopLocationsByCount()}
								loading={isLoadingTopLocationsByCount}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
							<div className={styles.downloadButton} style={{ width: 32, height: 32 }}>
								{
									isLoadingTopLocationsByCount ?
										<Spin /> :
										<img
											src='/img/download-green.svg'
											style={{
												cursor: !permissions.exportAnalytics ? 'not-allowed' : 'pointer',
												filter: !permissions.exportAnalytics ? 'invert(89%) sepia(0%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(104%)' : undefined
											}}
											onClick={() => downloadTopLocationsByCountCSV()}
										/>
								}
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<Dropdown
								overlayClassName={styles.overlay}
								trigger={['click']}
								overlay={() => {
									return (
										<Menu>
											{
												timePeriods.map(periodOption => {
													return (
														<Menu.Item
															key={periodOption.key}
															onClick={() => {
																if (periodOption.key === topLocationsByCountPeriodOption.key) {
																	return
																}
																setTopLocationsByCountPeriodOption(periodOption)
																if (periodOption.key !== 'custom') {
																	const { fromDate, toDate } = getDateRange(periodOption)
																	setTopLocationsByCountPeriodFromDate(fromDate)
																	setTopLocationsByCountPeriodToDate(toDate)
																}
															}}
														>
															{periodOption.label}
														</Menu.Item>
													)
												})
											}
										</Menu>
									)
								}}
							>
								<div className={styles.timeTabSelected}>
									{topLocationsByCountPeriodOption.label}
									<DownOutlined style={{ marginLeft: 12 }} />
								</div>
							</Dropdown>
						</div>
					</div>
					<div style={{ display: 'flex', marginTop: 6, marginRight: 6 }}>
						<div style={{ display: 'flex' }}>
							{topLocationsByCountPeriodOption.key === 'lifetime' ? <div className={styles.dateString} style={{ fontSize: 12 }}> Lifetime </div> :
								<>
									<div className={styles.dateString} style={{ fontSize: 12 }}>
										{
											topLocationsByCountPeriodOption.fromDate?.format(`MMM D, YYYY${topLocationsByCountPeriodOption.key === 'today' || topLocationsByCountPeriodOption.key === 'yesterday' || topLocationsByCountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											topLocationsByCountPeriodFromDate?.format(`MMM D, YYYY${topLocationsByCountPeriodOption.key === 'today' || topLocationsByCountPeriodOption.key === 'yesterday' || topLocationsByCountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											null
										}
									</div>
									<div className={styles.separator}>-</div>
									<div className={styles.dateString} style={{ fontSize: 12 }}>
										{
											topLocationsByCountPeriodOption.toDate?.format(`MMM D, YYYY${topLocationsByCountPeriodOption.key === 'today' || topLocationsByCountPeriodOption.key === 'yesterday' || topLocationsByCountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											topLocationsByCountPeriodToDate?.format(`MMM D, YYYY${topLocationsByCountPeriodOption.key === 'today' || topLocationsByCountPeriodOption.key === 'yesterday' || topLocationsByCountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											null
										}
									</div>
								</>}

						</div>
						{topLocationsByCountPeriodOption.key === 'custom' && renderTopLocationsByCountPeriodDateFilter()}
					</div>
					<div style={{ width: '100%', height: '320px' }}>
						<ResponsiveBar
							data={topLocationsByCount}
							keys={['count']}
							indexBy='district'
							colors={['#298EA4']}
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='horizontal'
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32,
								tickValues: getAxisBottomIntegerTickValues(0, topLocationsByCount?.length ? topLocationsByCount[topLocationsByCount.length - 1].count : 0, 11)
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 40
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
							tooltip={({ indexValue, formattedValue }) => {
								return (
									<div className={styles.tooltip}>
										<span>
											District:&nbsp;
											<strong>
												{indexValue}
											</strong>
										</span>
										<span>
											Total Order Count:&nbsp;
											<strong>
												{formattedValue}
											</strong>
										</span>
									</div>
								)
							}}
						/>
					</div>
					<div style={{ alignSelf: 'center', marginTop: '8px' }}>
						<Text strong={true}>{`Total Order Count: ${totalCount}`}</Text>
					</div>
				</div>
			</div>
		)
	}

	const getTopLocationsByAmount = async () => {
		setIsLoadingTopLocationsByAmount(true)
		try {
			const { data } = await SalesOrders.districtsBySalesOrders({
				status: orderStatuses.SALES_ORDER_APPROVED_STATUS,
				sortBy: 'amount',
				sortOrder: 'desc',
				maxRows: 10,
				fromDate: topLocationsByAmountPeriodFromDate?.toDate() || undefined,
				toDate: topLocationsByAmountPeriodToDate?.toDate() || undefined,
				locationIds: selectedLocationIds
			})
			setTopLocationsByAmount(data.results?.reverse() || [])
			setTotalAmount(data?.totalAmount || 0)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Top Sales Order Locations by Amount',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingTopLocationsByAmount(false)
		}
	}

	const downloadTopLocationsByAmountCSV = async () => {
		if (!permissions.exportAnalytics) {
			return
		}
		if (isDownloadingTopLocationsByAmount) {
			return
		}
		setIsDownloadingTopLocationsByAmount(true)
		const sortedTopLocationsByAmount = topLocationsByAmount.sort((a, b) => b.amount - a.amount)
		downloadArrayOfObjectsAsCSV(sortedTopLocationsByAmount, 'top_locations_by_order_amount', {
			headers: [
				{ key: 'district', name: 'District Name' },
				{ key: 'amount', name: 'Total Sales Value' }
			]
		})
		setIsDownloadingTopLocationsByAmount(false)
	}

	const renderTopLocationsByAmountPeriodDateFilter = () => {
		return (
			<div style={{ display: 'flex', marginLeft: 'auto' }}>
				<Space size='small'>
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='From'
						value={topLocationsByAmountPeriodFromDate || null}
						onChange={fromDate => {
							const fromDateStart = fromDate || null
							if (fromDateStart?.format(DATE_TIME_FORMAT) === topLocationsByAmountPeriodFromDate?.format(DATE_TIME_FORMAT)) {
								return
							}
							setTopLocationsByAmountPeriodFromDate(fromDateStart)
						}}
						disabledDate={current => current && topLocationsByAmountPeriodToDate && current.valueOf() > topLocationsByAmountPeriodToDate.valueOf()}
						showTime={{
							use12Hours: true
						}}
						format='YYYY-MM-DD h:mm A'
					/>
					<div className={styles.dash} />
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='To'
						value={topLocationsByAmountPeriodToDate || null}
						onChange={toDate => {
							const toDateEnd = toDate || null
							if (toDateEnd?.format(DATE_TIME_FORMAT) === topLocationsByAmountPeriodToDate?.format(DATE_TIME_FORMAT)) {
								return
							}
							setTopLocationsByAmountPeriodToDate(toDateEnd)
						}}
						disabledDate={current => current && topLocationsByAmountPeriodFromDate && current.valueOf() < topLocationsByAmountPeriodFromDate.valueOf()}
						showTime={{
							use12Hours: true
						}}
						format='YYYY-MM-DD h:mm A'
					/>
				</Space>
			</div>
		)
	}

	const renderTopLocationsByAmount = () => {
		return (
			<div>
				<div className={styles.graphContainer}>
					<div className={styles.tabInGraphContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
                                Order Value by Top 10 Locations
								<Tooltip title='Top 10 locations based on the total Approved order value on the selected time period' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								shape='circle'
								onClick={() => getTopLocationsByAmount()}
								loading={isLoadingTopLocationsByAmount}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
							<div className={styles.downloadButton} style={{ width: 32, height: 32 }}>
								{
									isLoadingTopLocationsByAmount ?
										<Spin /> :
										<img
											src='/img/download-green.svg'
											style={{
												cursor: !permissions.exportAnalytics ? 'not-allowed' : 'pointer',
												filter: !permissions.exportAnalytics ? 'invert(89%) sepia(0%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(104%)' : undefined
											}}
											onClick={() => downloadTopLocationsByAmountCSV()}
										/>
								}
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<Dropdown
								overlayClassName={styles.overlay}
								trigger={['click']}
								overlay={() => {
									return (
										<Menu>
											{
												timePeriods.map(periodOption => {
													return (
														<Menu.Item
															key={periodOption.key}
															onClick={() => {
																if (periodOption.key === topLocationsByAmountPeriodOption.key) {
																	return
																}
																setTopLocationsByAmountPeriodOption(periodOption)
																if (periodOption.key !== 'custom') {
																	const { fromDate, toDate } = getDateRange(periodOption)
																	setTopLocationsByAmountPeriodFromDate(fromDate)
																	setTopLocationsByAmountPeriodToDate(toDate)
																}
															}}
														>
															{periodOption.label}
														</Menu.Item>
													)
												})
											}
										</Menu>
									)
								}}
							>
								<div className={styles.timeTabSelected}>
									{topLocationsByAmountPeriodOption.label}
									<DownOutlined style={{ marginLeft: 12 }} />
								</div>
							</Dropdown>
						</div>
					</div>
					<div style={{ display: 'flex', marginTop: 6, marginRight: 6 }}>
						<div style={{ display: 'flex' }}>
							{
								topLocationsByAmountPeriodOption.key === 'lifetime' ? <div className={styles.dateString} style={{ fontSize: 12 }}> Lifetime </div> :
									<>
										<div className={styles.dateString} style={{ fontSize: 12 }}>
											{
												topLocationsByAmountPeriodOption.fromDate?.format(`MMM D, YYYY${topLocationsByAmountPeriodOption.key === 'today' || topLocationsByAmountPeriodOption.key === 'yesterday' || topLocationsByAmountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											topLocationsByAmountPeriodFromDate?.format(`MMM D, YYYY${topLocationsByAmountPeriodOption.key === 'today' || topLocationsByAmountPeriodOption.key === 'yesterday' || topLocationsByAmountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											null
											}
										</div>
										<div className={styles.separator}>-</div>
										<div className={styles.dateString} style={{ fontSize: 12 }}>
											{
												topLocationsByAmountPeriodOption.toDate?.format(`MMM D, YYYY${topLocationsByAmountPeriodOption.key === 'today' || topLocationsByAmountPeriodOption.key === 'yesterday' || topLocationsByAmountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											topLocationsByAmountPeriodToDate?.format(`MMM D, YYYY${topLocationsByAmountPeriodOption.key === 'today' || topLocationsByAmountPeriodOption.key === 'yesterday' || topLocationsByAmountPeriodOption.key === 'custom' ? ' h:mm A' : ''}`) ||
											null
											}
										</div>
									</>
							}
						</div>
						{topLocationsByAmountPeriodOption.key === 'custom' && renderTopLocationsByAmountPeriodDateFilter()}
					</div>
					<div style={{ width: '100%', height: '320px' }}>
						<ResponsiveBar
							data={topLocationsByAmount}
							keys={['amount']}
							indexBy='district'
							colors={['#298EA4']}
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='horizontal'
							valueFormat={value => getFormattedCurrency(value)}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32,
								tickValues: getAxisBottomIntegerTickValues(0, topLocationsByAmount?.length ? topLocationsByAmount[topLocationsByAmount.length - 1].amount : 0, 11)
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 40
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
							tooltip={({ indexValue, formattedValue }) => {
								return (
									<div className={styles.tooltip}>
										<span>
											District:&nbsp;
											<strong>
												{indexValue}
											</strong>
										</span>
										<span>
											Total Sales Value:&nbsp;
											<strong>
												{formattedValue}
											</strong>
										</span>
									</div>
								)
							}}
						/>
					</div>
					<div style={{ alignSelf: 'center', marginTop: '8px' }}>
						<Text strong={true}>{`Total Sales Value: ${getFormattedCurrency(totalAmount)}`}</Text>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.statContainer} style={{ marginTop: 24 }}>
			<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
				<Row gutter={30}>
					<Col flex={1} span={12} className={styles.statColumnLeft}>
						{renderTopLocationsByCount()}
					</Col>
					<Col flex={1} span={12} className={styles.statColumnRight}>
						{renderTopLocationsByAmount()}
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default TopSalesOrderLocations
