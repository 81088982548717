import Page from '../components/page'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { claimsRoles, paths } from '../utils/constants'
import { industries } from '../utils/constants'
import ECommerceDashboard from '../components/pages/e-commerce-dashboard'
import Dashboard from '../components/pages/dashboard'
import AdminDashboard from '../components/pages/admin-dashboard'

const HomePage = () => {
	const router = useRouter()
	const { role, companyDetails, permissions } = useSelector(state => state.authReducer)

	useEffect(() => {
		if (role !== claimsRoles.ADMIN) {
			if (permissions?.viewDashboardAnalytics === false) {
				router.push(paths.SALES_ORDERS)
			}
		}
	}, [permissions])

	const renderHomePage = () => {
		if (!role) {
			return null
		}
		if (!companyDetails && role !== claimsRoles.ADMIN) {
			return null
		}
		if (role === claimsRoles.ADMIN) {
			return (
				<AdminDashboard />
			)
		}
		if (permissions?.viewDashboardAnalytics === true) {
			if (companyDetails.industry === industries.E_COMMERCE) {
				return (
					<ECommerceDashboard />
				)
			} else {
				return (
					<Dashboard />
				)
			}
		} else {
			return null
		}
	}

	return (
		<>
			{renderHomePage()}
		</>
	)
}


HomePage.getLayout = function getLayout (page) {
	return (
		<Page>
			{page}
		</Page>
	)
}

export default HomePage
