import styles from './Dashboard.module.css'
import moment from 'moment'
import { Space, DatePicker, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGeneralAnalyticsOrderCycleTime, fetchGeneralAnalyticsOrderLeadTime, fetchGeneralAnalyticsOrderRate, fetchGeneralAnalyticsStatistics, fetchSalesAnalyticsDistributorGrowth, fetchSalesAnalyticsDistributorSales, fetchSalesAnalyticsDivisionSales, fetchSalesAnalyticsItemSales, fetchSalesAnalyticsOrders, fetchSalesAnalyticsSales, fetchOrdersAssignedAndCompletedRatio, fetchCollectedAmountRatio, fetchDeliveryPartnerByLocation, fetchDistanceTraveledByDeliveryPartner } from '../../../store/dashboard/actions'
import { ArrowUpOutlined, ArrowDownOutlined, ReloadOutlined } from '@ant-design/icons'
import { useDebounceValue } from '../../../hooks/useDebounceValue'
import { getCurrencyCode, getFormattedCurrency } from '../../../utils'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { useRouter } from 'next/router'
import { ResponsivePie } from '@nivo/pie'
import HeaderTab from '../../header-tab'
import { claimsRoles } from '../../../utils/constants'

const { Search } = Input

const tabs = [
	{
		title: 'General Analytics',
		value: 'general_analytics'
	},
	{
		title: 'Sales Analytics',
		value: 'sales_analytics'
	},
	{
		title: 'Delivery Partner Analytics',
		value: 'delivery_partner_analytics'
	}
]
const timeDifferentiateTabs = [
	{
		title: 'Daily',
		value: 'daily'
	},
	{
		title: 'Weekly',
		value: 'weekly'
	},
	{
		title: 'Monthly',
		value: 'monthly'
	}
]

const leadTimeTabs = [
	{
		title: 'Weekly',
		value: 'weekly'
	},
	{
		title: 'Monthly',
		value: 'monthly'
	}
]

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => {
	return (
		<g>
			<circle fill='#fff' r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
			<circle
				r={size / 5}
				strokeWidth={borderWidth}
				stroke={borderColor}
				fill={color}
				fillOpacity={0.35}
			/>
		</g>
	)
}

export const getPieChartData = (orderRateData) => {
	return [
		{
			'id': 'Flagged',
			'label': 'Flagged',
			'value': orderRateData.flagged || 0,
			'color': '#FF5959'
		},
		{
			'id': 'Approved',
			'label': 'Approved',
			'value': orderRateData.approved || 0,
			'color': '#49BEB7'
		},
		{
			'id': 'Processing',
			'label': 'Processing',
			'value': orderRateData.processing || 0,
			'color': '#288EA5'
		},
		{
			'id': 'Cancelled',
			'label': 'Cancelled',
			'value': orderRateData.cancelled || 0,
			'color': '#FF0000'
		},
		{
			'id': 'Requested',
			'label': 'Requested',
			'value': orderRateData.requested || 0,
			'color': '#1F4286'
		},
		{
			'id': 'Delivered',
			'label': 'Delivered',
			'value': orderRateData.completed || 0,
			'color': '#085F63'
		}
	]
}

const getOrderCycleChartData = (orderCycleChart) => {
	return [
		{
			'id': 'Processing',
			'value': orderCycleChart.PROCESSING || 0,
			'color': '#288EA5'
		},
		{
			'id': 'Approved',
			'value': orderCycleChart.APPROVED || 0,
			'color': '#49BEB7'
		},
		{
			'id': 'Requested',
			'value': orderCycleChart.REQUESTED || 0,
			'color': '#1F4286'
		}
	]
}

const Dashboard = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const {
		generalAnalyticsStatistics,
		generalAnalyticsOrderRate,
		generalAnalyticsOrderCycleTime,
		generalAnalyticsOrderLeadTime,
		salesAnalyticsOrders,
		salesAnalyticsSales,
		ordersAssignedAndCompletedRatio,
		driverWiseCollectedAmountRatio,
		deliveryPartnerByLocation,
		distanceTraveledByDeliveryPartner,
		salesAnalyticsDistributorGrowth,
		salesAnalyticsDivisionSales,
		salesAnalyticsItemSales,
		salesAnalyticsDistributorSales
	} = useSelector(state => state.dashboardReducer)
	const { role, companyDetails, permissions } = useSelector(state => state.authReducer)
	const [isLoadingGeneralAnalyticsStatistics, setIsLoadingGeneralAnalyticsStatistics] = useState(false)
	const [isOrdersLoading, setIsOrdersLoading] = useState(false)
	const [isSalesLoading, setIsSalesLoading] = useState(false)
	const [isLoadingGeneralAnalyticsOrderRate, setIsLoadingGeneralAnalyticsOrderRate] = useState(false)
	const [isLoadingGeneralAnalyticsOrderCycleTime, setIsLoadingGeneralAnalyticsOrderCycleTime] = useState(false)
	const [isLoadingGeneralAnalyticsOrderLeadTime, setIsLoadingGeneralAnalyticsOrderLeadTime] = useState(false)
	const [isLoadingSalesAnalyticsOrderTrends, setIsLoadingSalesAnalyticsOrderTrends] = useState(false)
	const [isLoadingSalesAnalyticsAmountTrends, setIsLoadingSalesAnalyticsAmountTrends] = useState(false)
	const [isLoadingSalesAnalyticsDistributorGrowth, setIsLoadingSalesAnalyticsDistributorGrowth] = useState(false)
	const [isLoadingSalesAnalyticsDivisionSales, setIsLoadingSalesAnalyticsDivisionSales] = useState(false)
	const [isLoadingSalesAnalyticsItemSales, setIsLoadingSalesAnalyticsItemSales] = useState(false)
	const [isLoadingSalesAnalyticsDistributorSales, setIsLoadingSalesAnalyticsDistributorSales] = useState(false)
	const [isLoadingOrdersAssignedAndCompletedRatio, setIsLoadingOrdersAssignedAndCompletedRatio] = useState(false)
	const [isLoadingDriverWiseCollectedAmountRatio, setIsLoadingDriverWiseCollectedAmountRatio] = useState(false)
	const [isLoadingDeliveryPartnerByLocation, setIsLoadingDeliveryPartnerByLocation] = useState(false)
	const [isLoadingDistanceTraveledByDeliveryPartner, setIsLoadingDistanceTraveledByDeliveryPartner] = useState(false)
	const [currentTab, setCurrentTab] = useState(tabs[0].value)
	const [fromDate, setFromDate] = useState(null)
	const [leadTimeDate, setLeadTimeDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState(null)
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
	const [generalOrderCountCurrentTimeTab, setGeneralOrderCountCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [generalOrderCountPieChartTimeTab, setGeneralOrderCountPieChartTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [generalSalesOrderAmountCurrentTimeTab, setGeneralSalesOrderAmountCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [distributorGrowthCurrentTimeTab, setDistributorGrowthCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [divisionWiseSalesCurrentTimeTab, setDivisionWiseSalesCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [distributorWiseSalesCurrentTimeTab, setDistributorWiseSalesCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [itemWiseSalesCurrentTimeTab, setItemWiseSalesCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [orderCycleTimeTab, setOrderCycleTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [leadTimeCurrentTimeTab, setLeadTimeCurrentTimeTab] = useState(leadTimeTabs[0].value)
	const [ordersAssignedAndCompletedRatioTimeTab, setOrdersAssignedAndCompletedRatioTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [driverWiseCollectedAmountTab, setDriverWiseCollectedAmountTab] = useState(timeDifferentiateTabs[0].value)
	const [deliveryPartnerByLocationTab, setDeliveryPartnerByLocationTab] = useState(timeDifferentiateTabs[0].value)
	const [distanceTraveledByDeliveryPartnerTab, setDistanceTraveledByDeliveryPartnerTab] = useState(timeDifferentiateTabs[0].value)

	const analyticsTypes = {
		orders: 'orders',
		sales: 'sales',
		all: 'all'
	}

	useEffect(() => {
		getGeneralAnalytics()
	}, [])

	useEffect(() => {
		getGeneralAnalyticsOrderCycleTime(orderCycleTimeTab)
	}, [fromDate, toDate, debouncedSearchTerm])

	useEffect(() => {
		getGeneralAnalyticsOrderLeadTime(leadTimeCurrentTimeTab)
	}, [leadTimeDate])

	useEffect(() => {
		if (role === claimsRoles.ADMIN) {
			router.push('/companies')
		}
	}, [role])

	const getGeneralAnalytics = () => {
		if (!permissions.viewGeneralAnalytics) {
			return
		}
		getGeneralAnalyticsStatistics(analyticsTypes.all)
		getGeneralAnalyticsOrderRate()
	}

	const updateAnalyticsLoadingStates = (type, state) => {
		if (type === analyticsTypes.all) {
			setIsLoadingGeneralAnalyticsStatistics(state)
		}
		if (type === analyticsTypes.orders) {
			setIsOrdersLoading(state)
		}
		if (type === analyticsTypes.sales) {
			setIsSalesLoading(state)
		}
	}

	const getGeneralAnalyticsStatistics = async (type) => {
		updateAnalyticsLoadingStates(type, true)
		await dispatch(fetchGeneralAnalyticsStatistics())
		updateAnalyticsLoadingStates(type, false)
	}

	const getGeneralAnalyticsOrderRate = async (interval) => {
		setIsLoadingGeneralAnalyticsOrderRate(true)
		let fromDate
		let toDate
		switch (interval) {
			case 'daily':
				fromDate = moment().startOf('day')
				toDate = moment().endOf('day')
				break
			case 'weekly':
				fromDate = moment().startOf('week')
				toDate = moment().endOf('week')
				break
			case 'monthly':
				fromDate = moment().startOf('month')
				toDate = moment().endOf('month')
				break
			default:
				fromDate = moment().startOf('day')
				toDate = moment().endOf('day')
				break
		}
		await dispatch(fetchGeneralAnalyticsOrderRate(interval, fromDate?.toDate(), toDate?.toDate()))
		setIsLoadingGeneralAnalyticsOrderRate(false)
	}

	const getGeneralAnalyticsOrderCycleTime = async (interval) => {
		setIsLoadingGeneralAnalyticsOrderCycleTime(true)
		let fromDate
		let toDate
		switch (interval) {
			case 'daily':
				fromDate = moment().startOf('day')
				toDate = moment().endOf('day')
				break
			case 'weekly':
				fromDate = moment().subtract(7, 'days')
				toDate = moment()
				break
			case 'monthly':
				fromDate = moment().subtract(30, 'days')
				toDate = moment()
				break
			default:
				fromDate = moment().startOf('day')
				toDate = moment().endOf('day')
				break
		}
		await dispatch(fetchGeneralAnalyticsOrderCycleTime({
			interval,
			fromDate: fromDate?.toDate(),
			toDate: toDate?.toDate()
		}))
		setIsLoadingGeneralAnalyticsOrderCycleTime(false)
	}

	const getGeneralAnalyticsOrderLeadTime = async (interval) => {
		setIsLoadingGeneralAnalyticsOrderLeadTime(true)
		await dispatch(fetchGeneralAnalyticsOrderLeadTime(getLeadTimeSearchParams(interval)))
		setIsLoadingGeneralAnalyticsOrderLeadTime(false)
	}

	const getSalesAnalytics = () => {
		if (!permissions.viewSalesAnalytics) {
			return
		}
		getSalesAnalyticsOrderTrends()
		getSalesAnalyticsSalesTrends()
		getSalesAnalyticsDistributorGrowth()
		getSalesAnalyticsDivisionSales()
		getSalesAnalyticsItemSales()
		getSalesAnalyticsDistributorSales()
	}

	const getDeliveryPartnerAnalytics = () => {
		getAssignedAndCompletedOrdersRatio()
		getDriverWiseCollectedAmountRatio()
		getDeliveryPartnerByLocation()
		getDistanceTraveledByDeliveryPartner()
	}

	const getSalesAnalyticsOrderTrends = async (interval) => {
		setIsLoadingSalesAnalyticsOrderTrends(true)
		await dispatch(fetchSalesAnalyticsOrders(interval))
		setIsLoadingSalesAnalyticsOrderTrends(false)
	}

	const getSalesAnalyticsSalesTrends = async (interval) => {
		setIsLoadingSalesAnalyticsAmountTrends(true)
		await dispatch(fetchSalesAnalyticsSales(interval))
		setIsLoadingSalesAnalyticsAmountTrends(false)
	}

	const getAssignedAndCompletedOrdersRatio = async (interval) => {
		setIsLoadingOrdersAssignedAndCompletedRatio(true)
		await dispatch(fetchOrdersAssignedAndCompletedRatio(interval))
		setIsLoadingOrdersAssignedAndCompletedRatio(false)
	}

	const getDriverWiseCollectedAmountRatio = async (interval) => {
		setIsLoadingDriverWiseCollectedAmountRatio(true)
		await dispatch(fetchCollectedAmountRatio(interval))
		setIsLoadingDriverWiseCollectedAmountRatio(false)
	}

	const getDeliveryPartnerByLocation = async (interval) => {
		setIsLoadingDeliveryPartnerByLocation(true)
		await dispatch(fetchDeliveryPartnerByLocation(interval))
		setIsLoadingDeliveryPartnerByLocation(false)
	}

	const getDistanceTraveledByDeliveryPartner = async (interval) => {
		setIsLoadingDistanceTraveledByDeliveryPartner(true)
		await dispatch(fetchDistanceTraveledByDeliveryPartner(interval))
		setIsLoadingDistanceTraveledByDeliveryPartner(false)
	}

	const getSalesAnalyticsDistributorGrowth = async (interval) => {
		setIsLoadingSalesAnalyticsDistributorGrowth(true)
		await dispatch(fetchSalesAnalyticsDistributorGrowth(interval))
		setIsLoadingSalesAnalyticsDistributorGrowth(false)
	}

	const getSalesAnalyticsDivisionSales = async (interval) => {
		setIsLoadingSalesAnalyticsDivisionSales(true)
		await dispatch(fetchSalesAnalyticsDivisionSales(interval))
		setIsLoadingSalesAnalyticsDivisionSales(false)
	}

	const getSalesAnalyticsItemSales = async (interval) => {
		setIsLoadingSalesAnalyticsItemSales(true)
		await dispatch(fetchSalesAnalyticsItemSales(interval))
		setIsLoadingSalesAnalyticsItemSales(false)
	}

	const getSalesAnalyticsDistributorSales = async (interval) => {
		setIsLoadingSalesAnalyticsDistributorSales(true)
		await dispatch(fetchSalesAnalyticsDistributorSales(interval))
		setIsLoadingSalesAnalyticsDistributorSales(false)
	}

	const getOrderCountTimeWiseXAxisValue = (d) => {
		if (generalOrderCountCurrentTimeTab === 'daily') {
			return +d.split('-')[2]
		} else if (generalOrderCountCurrentTimeTab === 'weekly') {
			return +d.split('-')[1]
		} else if (generalOrderCountCurrentTimeTab === 'monthly') {
			return +d.split('-')[1]
		}
	}

	const getLeadTimeSearchParams = (interval) => {
		const params = {}
		if (leadTimeDate) {
			params.leadTimeDate = leadTimeDate
		} else {
			params.interval = interval
		}
		return params
	}

	const setDistributorGrowthTimeWiseXAxisValue = (d) => {
		if (distributorGrowthCurrentTimeTab === 'daily') {
			return `${moment(d).format('ddd')}`
		} else if (distributorGrowthCurrentTimeTab === 'weekly') {
			const dateRange = d.split('_')
			const weekStart = moment(dateRange[0]).format('DD, MMM')
			const weekEnd = moment(dateRange[1]).format('DD, MMM')
			return `${weekStart} - ${weekEnd}`
		} else if (distributorGrowthCurrentTimeTab === 'monthly') {
			return `${moment(d).format('MMM')}`
		}
	}

	const setDivisionWiseSalesAmountTimeRange = (d) => {
		if (divisionWiseSalesCurrentTimeTab === 'daily') {
			return d
		} else if (divisionWiseSalesCurrentTimeTab === 'weekly') {
			return d
		} else if (divisionWiseSalesCurrentTimeTab === 'monthly') {
			return d
		}
	}

	const getDivisionWiseLineData = trends => {
		const data = {
			id: 'division',
			data: trends.map(trend => ({ x: trend.division, y: (+trend.value).toFixed(3) }))
		}
		return data
	}

	const getValueWiseLineData = trends => {
		const data = {
			id: 'name',
			data: trends.map(trend => ({ x: trend.name, y: (+trend.value).toFixed(3) }))
		}
		return data
	}

	const setOrderAmountTimeWiseXAxisValue = (d) => {
		if (generalSalesOrderAmountCurrentTimeTab === 'daily') {
			return `${moment(d).format('ddd')}`
		} else if (generalSalesOrderAmountCurrentTimeTab === 'weekly') {
			const dateRange = d.split('_')
			const weekStart = moment(dateRange[0]).format('DD, MMM')
			const weekEnd = moment(dateRange[1]).format('DD, MMM')
			return `${weekStart} - ${weekEnd}`
		} else if (generalSalesOrderAmountCurrentTimeTab === 'monthly') {
			return `${moment(d).format('MMM')}`
		}
	}

	const mainTabs = tabs.filter(tab => {
		if (tab.value === 'general_analytics' && !permissions.viewGeneralAnalytics) {
			return false
		}
		if (tab.value === 'sales_analytics' && !permissions.viewSalesAnalytics) {
			return false
		}
		if (tab.value === 'delivery_partner_analytics') {
			return companyDetails?.isDeliveryPlanEnabled
		}
		return true
	})

	const renderPercentage = percentageChange => {
		return (
			percentageChange ?
				percentageChange >= 0 ?
					<div className={styles.percentageContainer}>
						<ArrowUpOutlined style={{ color: '#00BA88', fontSize: 16 }} />
						<div className={styles.percentagePositive}>{percentageChange.toFixed(2)}%</div>
						<div className={styles.yesterday}>than yesterday</div>
					</div> :
					<div className={styles.percentageContainer}>
						<ArrowDownOutlined style={{ color: '#FF5959', fontSize: 16 }} />
						<div className={styles.percentageNegative}>{percentageChange.toFixed(2)}%</div>
						<div className={styles.yesterday}>than yesterday</div>
					</div> :
				<div className={styles.percentageContainer} style={{ color: '#64748B' }}>
					--
				</div>
		)
	}

	const renderDateAndManagerFilter = () => {
		return (
			<div style={{ display: 'flex', marginTop: 5 }}>
				<Space
					size='small'
				>
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='From'
						value={fromDate ? moment(fromDate) : null }
						onChange={(date) => setFromDate(date?.toDate())}
					/>
					<div className={styles.dash} />
					<DatePicker
						dropdownClassName={styles.overlay}
						placeholder='To'
						value={toDate ? moment(toDate) : null }
						onChange={(date) => {
							setOrderCycleTimeTab(null)
							setToDate(date?.toDate())
						}}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
					<Search
						placeholder='Search Area Manager'
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
						onSearch={value => setSearchTerm(value)}
						enterButton={false}
						style={{ width: 260 }}
					/>
				</Space>
			</div>
		)
	}

	const renderOrderRateCycleTimeBarChart = () => {
		const { orderCycleChart, orderCycleChartWeekly, orderCycleChartMonthly } = generalAnalyticsOrderCycleTime
		let orderCycleTimeTabData = getOrderCycleChartData(orderCycleChart)
		if (orderCycleTimeTab === 'weekly') {
			orderCycleTimeTabData = getOrderCycleChartData(orderCycleChartWeekly)
		} else if (orderCycleTimeTab === 'monthly') {
			orderCycleTimeTabData = getOrderCycleChartData(orderCycleChartMonthly)
		}

		return (
			<div className={styles.singleChartContainer} style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 24 }}>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }}>Order Cycle Time</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsOrderCycleTime(orderCycleTimeTab)}
								loading={isLoadingGeneralAnalyticsOrderCycleTime}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							{timeDifferentiateTabs.map(tabItem => {
								return (
									<div
										onClick={() => {
											if (fromDate) {
												setFromDate(null)
											}
											if (toDate) {
												setToDate(null)
											}
											setOrderCycleTimeTab(tabItem.value)
											getGeneralAnalyticsOrderCycleTime(tabItem.value)
										}}
										className={orderCycleTimeTab === tabItem.value && !(fromDate && toDate) ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
									>
										{tabItem.title}
									</div>
								)
							})}
						</div>
					</div>
					{renderDateAndManagerFilter()}
					<div style={{ height: 320 }}>
						<ResponsiveBar
							data={orderCycleTimeTabData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='horizontal'
							valueFormat={value => {
								if (value < 1) {
									return `${(value * 60).toFixed(2)} mins`
								} else {
									return `${value.toFixed(2)} hrs`
								}
							}}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 40
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
				</div>
			</div>
		)
	}

	const renderLeadTimeChart = () => {
		const { salesOrderLeadTimeWeekly, salesOrderLeadTimeMonthly } = generalAnalyticsOrderLeadTime
		let generalSalesOrderAmountData = salesOrderLeadTimeWeekly ? salesOrderLeadTimeWeekly : []
		if (leadTimeCurrentTimeTab === 'monthly') {
			generalSalesOrderAmountData = salesOrderLeadTimeMonthly ? salesOrderLeadTimeMonthly : []
		}
		return (
			<div className={styles.singleChartContainer}>
				<div style={{ marginTop: '20px' }} className={styles.graphContainer}>
					<div style={{ display: 'flex' }}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }}>Lead Time</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsOrderLeadTime(leadTimeCurrentTimeTab)}
								loading={isLoadingGeneralAnalyticsOrderLeadTime}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							<div className={styles.timeTabContainer}>
								{leadTimeTabs.map(leadTimeTab => {
									return (
										<div
											onClick={() => {
												if (leadTimeDate) {
													setLeadTimeDate(null)
												}
												setLeadTimeCurrentTimeTab(leadTimeTab.value)
												getGeneralAnalyticsOrderLeadTime(leadTimeTab.value)
											}}
											className={leadTimeCurrentTimeTab === leadTimeTab.value ? styles.timeTabSelected : styles.timeTab} key={leadTimeTab.value}
										>
											<div className={leadTimeTab.title}>{leadTimeTab.title}</div>
										</div>
									)
								})}
								<Space style={{ marginLeft: 5 }}>
									<DatePicker
										dropdownClassName={styles.overlay}
										placeholder='Date'
										value={leadTimeDate ? moment(leadTimeDate) : null}
										onChange={(date) => {
											setLeadTimeCurrentTimeTab(null)
											setLeadTimeDate(date?.toDate())
										}}
									/>
								</Space>
							</div>
						</div>
					</div>
					<ResponsiveBar
						colors={['#298EA4']}
						data={generalSalesOrderAmountData}
						indexBy='date'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legendPosition: 'middle',
							legendOffset: -55,
							format: value => `${value} hours`
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderOrderRatePercentageChart = () => {
		let generalPieChartData = getPieChartData(generalAnalyticsOrderRate.todayOrderRate)
		if (generalOrderCountPieChartTimeTab === 'weekly') {
			generalPieChartData = getPieChartData(generalAnalyticsOrderRate.sevenDayOrderRate)
		} else if (generalOrderCountPieChartTimeTab === 'monthly') {
			generalPieChartData = getPieChartData(generalAnalyticsOrderRate.oneMonthOrderRate)
		}

		return (
			<div className={styles.singlePieChartContainer} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
				<div className={`${styles.pieAndCycleGraphContainer} pie-chart-container`}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }}>Order Rate %</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsOrderRate(generalOrderCountPieChartTimeTab)}
								loading={isLoadingGeneralAnalyticsOrderRate}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							{timeDifferentiateTabs.map(tabItem => {
								return (
									<div
										onClick={() => {
											setGeneralOrderCountPieChartTimeTab(tabItem.value)
											getGeneralAnalyticsOrderRate(tabItem.value)
										}}
										className={generalOrderCountPieChartTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
									>
										{tabItem.title}
									</div>
								)
							})}
						</div>
					</div>
					<ResponsivePie
						data={generalPieChartData}
						colors={{ datum: 'data.color' }}
						height={375}
						margin={{ top: 40, right: 80, bottom: 60, left: 160 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						valueFormat={value => {
							return value !== null ? `${Number(value).toLocaleString(value, {
								minimumFractionDigits: 2
							})} %` : `${0} %`
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'top-left',
								direction: 'column',
								justify: false,
								translateX: -160,
								translateY: 0,
								itemsSpacing: 10,
								itemWidth: 90,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 18,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
				</div>
			</div>)
	}

	const renderSalesCountChart = () => {
		let generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrend.map(data => ({ date: data.day, value: data.count }))
		if (generalOrderCountCurrentTimeTab === 'weekly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendWeekly.map(data => ({ date: data.week, value: data.count }))
		} else if (generalOrderCountCurrentTimeTab === 'monthly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendMonthly.map(data => ({ date: data.month, value: data.count }))
		}
		return (
			<div className={styles.singleChartContainer}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
					<h3 style={{ marginBottom: 0 }}>Orders Count</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsOrderTrends(generalOrderCountCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsOrderTrends}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setGeneralOrderCountCurrentTimeTab(tabItem.value)
										getSalesAnalyticsOrderTrends(tabItem.value)
									}}
									className={generalOrderCountCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									{tabItem.title}
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#298EA4']}
						data={generalOrderCountData}
						indexBy='date'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: (d) => getOrderCountTimeWiseXAxisValue(d)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 1,
							tickRotation: 0,
							legend: 'Orders Count',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						tooltipLabel={data => generalOrderCountCurrentTimeTab !== 'daily' ? `${generalOrderCountCurrentTimeTab === 'weekly' ? 'Week' : 'Month'} ${getOrderCountTimeWiseXAxisValue(data.indexValue)}` : data.indexValue}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderSalesAmountChart = () => {
		let generalSalesOrderAmountData = salesAnalyticsSales.salesOrderAmountTrend.slice(1, 8)
		if (generalSalesOrderAmountCurrentTimeTab === 'weekly') {
			generalSalesOrderAmountData = salesAnalyticsSales.salesOrderAmountTrendWeekly.slice(1, 5)
		} else if (generalSalesOrderAmountCurrentTimeTab === 'monthly') {
			generalSalesOrderAmountData = salesAnalyticsSales.salesOrderAmountTrendMonthly.slice(2, 14)
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
					<h3 style={{ marginBottom: 0 }}>Sales Trends</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsSalesTrends(generalSalesOrderAmountCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsAmountTrends}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setGeneralSalesOrderAmountCurrentTimeTab(tabItem.value)
										getSalesAnalyticsSalesTrends(tabItem.value)
									}}
									className={generalSalesOrderAmountCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#298EA4']}
						data={generalSalesOrderAmountData.map(d => {
							return {
								date: d.date,
								value: (+d.value).toFixed(3)
							}
						})}
						indexBy='date'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: (e) => setOrderAmountTimeWiseXAxisValue(e)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Sales x 10,000',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderOrdersAssignedAndCompletedRatioChart = () => {
		let ordersAssignedAndCompletedRatioData = ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioDaily.slice(0, 19)
		if (ordersAssignedAndCompletedRatioTimeTab === 'weekly') {
			ordersAssignedAndCompletedRatioData = ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioWeekly.slice(0, 19)
		} else if (ordersAssignedAndCompletedRatioTimeTab === 'monthly') {
			ordersAssignedAndCompletedRatioData = ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioMonthly.slice(0, 19)
		}

		return (
			<div className={styles.singleChartContainer}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
					<h3 style={{ marginBottom: 0 }}>Ratio of Orders Assigned/Delivered Orders</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getAssignedAndCompletedOrdersRatio(ordersAssignedAndCompletedRatioTimeTab)}
						loading={isLoadingOrdersAssignedAndCompletedRatio}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setOrdersAssignedAndCompletedRatioTimeTab(tabItem.value)
										getAssignedAndCompletedOrdersRatio(tabItem.value)
									}}
									className={ordersAssignedAndCompletedRatioTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#2B8EA3', '#52C7A6']}
						groupMode='grouped'
						enableGridY={true}
						enableGridX={false}
						keys={[ 'Total Assigned Sales Order', 'Delivered Sales Order' ]}
						data={ordersAssignedAndCompletedRatioData && ordersAssignedAndCompletedRatioData.map(d => {
							return {
								name: d.name,
								'Total Assigned Sales Order': d.totalAssignedSalesOrder,
								'Delivered Sales Order': d.completedSalesOrder
							}
						})}
						indexBy='name'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: value => value.slice(0, 5)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Order Assigned/Order Delivered',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderDriverWiseCollectedAmountRatioChart = () => {
		let driverWiseCollectedAmount = driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioDaily.slice(0, 19)
		if (driverWiseCollectedAmountTab === 'weekly') {
			driverWiseCollectedAmount = driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioWeekly.slice(0, 19)
		} else if (driverWiseCollectedAmountTab === 'monthly') {
			driverWiseCollectedAmount = driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioMonthly.slice(0, 19)
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
					<h3 style={{ marginBottom: 0, marginRight: 10 }}>Ratio of Cash Collected/Order Value</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getDriverWiseCollectedAmountRatio(driverWiseCollectedAmountTab)}
						loading={isLoadingDriverWiseCollectedAmountRatio}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDriverWiseCollectedAmountTab(tabItem.value)
										getDriverWiseCollectedAmountRatio(tabItem.value)
									}}
									className={driverWiseCollectedAmountTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#2B8EA3', '#52C7A6']}
						groupMode='grouped'
						enableGridY={true}
						enableGridX={false}
						keys={[ 'Total Sales Order Amount', 'Collected Sales Order Amount' ]}
						data={driverWiseCollectedAmount && driverWiseCollectedAmount.map(d => {
							return {
								name: d.name,
								'Total Sales Order Amount': d.totalSalesOrderAmount / 10000,
								'Collected Sales Order Amount': d.collectedSalesOrderAmount / 10000
							}
						})}
						indexBy='name'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: value => value.slice(0, 5)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Cash Collected/Order Value x 10,000',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderDeliveryPartnerByLocationChart = () => {
		let deliveryPartners = deliveryPartnerByLocation.deliveryPartnerByLocationDaily
		if (deliveryPartnerByLocationTab === 'weekly') {
			deliveryPartners = deliveryPartnerByLocation.deliveryPartnerByLocationWeekly
		} else if (deliveryPartnerByLocationTab === 'monthly') {
			deliveryPartners = deliveryPartnerByLocation.deliveryPartnerByLocationMonthly
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 50 }}>
					<h3 style={{ marginBottom: 0, marginRight: 10 }}>Delivery Partner By Location</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getDeliveryPartnerByLocation(deliveryPartnerByLocationTab)}
						loading={isLoadingDeliveryPartnerByLocation}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDeliveryPartnerByLocationTab(tabItem.value)
										getDeliveryPartnerByLocation(tabItem.value)
									}}
									className={deliveryPartnerByLocationTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#52C6A4']}
						enableGridY={true}
						enableGridX={false}
						keys={[ 'Number Of Drivers' ]}
						data={deliveryPartners && deliveryPartners.map(d => {
							return {
								division: d.division,
								'Number Of Drivers': d.numberOfDrivers
							}
						})}
						indexBy='division'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Count',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderDistanceTraveledByDeliveryPartnerChart = () => {
		let distanceTraveledByDrivers = distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerDaily
		if (distanceTraveledByDeliveryPartnerTab === 'weekly') {
			distanceTraveledByDrivers = distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerWeekly
		} else if (distanceTraveledByDeliveryPartnerTab === 'monthly') {
			distanceTraveledByDrivers = distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerMonthly
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 50 }}>
					<h3 style={{ marginBottom: 0, marginRight: 10 }}>Distance Traveled By Delivery Partner</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getDistanceTraveledByDeliveryPartner(distanceTraveledByDeliveryPartnerTab)}
						loading={isLoadingDistanceTraveledByDeliveryPartner}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDistanceTraveledByDeliveryPartnerTab(tabItem.value)
										getDistanceTraveledByDeliveryPartner(tabItem.value)
									}}
									className={distanceTraveledByDeliveryPartnerTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#52C6A4']}
						enableGridY={true}
						enableGridX={false}
						keys={[ 'Distance Traveled' ]}
						data={distanceTraveledByDrivers && distanceTraveledByDrivers.map(d => {
							return {
								name: d.name,
								'Distance Traveled': d.distanceTraveled / 10000
							}
						})}
						indexBy='name'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: value => value.slice(0, 5)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: 'Distance Traveled (KM) x 10,000 ',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderDistributorGrowthChart = () => {
		let distributorGrowthData = salesAnalyticsDistributorGrowth.distributorGrowthAmountDaily.slice(1, 8)
		if (distributorGrowthCurrentTimeTab === 'weekly') {
			distributorGrowthData = salesAnalyticsDistributorGrowth.distributorGrowthAmountWeekly.slice(1, 5)
		} else if (distributorGrowthCurrentTimeTab === 'monthly') {
			distributorGrowthData = salesAnalyticsDistributorGrowth.distributorGrowthAmountMonthly.slice(2, 14)
		}

		return (
			<div className={styles.singleChartContainer}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 24 }}>
					<h3 style={{ marginBottom: 0 }}>Distributor Growth</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsDistributorGrowth(distributorGrowthCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsDistributorGrowth}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDistributorGrowthCurrentTimeTab(tabItem.value)
										getSalesAnalyticsDistributorGrowth(tabItem.value)
									}}
									className={distributorGrowthCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									<div className={tabItem.title}>{tabItem.title}</div>
								</div>
							)
						})}
					</div>
					<ResponsiveBar
						colors={['#298EA4']}
						data={distributorGrowthData.map(d => {
							return {
								date: d.date,
								value: (+d.value).toFixed(3)
							}
						})}
						indexBy='date'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						enableLabel={false}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: (d) => setDistributorGrowthTimeWiseXAxisValue(d)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 3,
							tickRotation: 0,
							legend: 'Growth',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
					/>
				</div>
			</div>
		)
	}

	const renderDivisionWiseSalesChart = () => {
		let divisionWiseSalesData = salesAnalyticsDivisionSales.divisionWiseSalesDaily
		if (divisionWiseSalesCurrentTimeTab === 'weekly') {
			divisionWiseSalesData = salesAnalyticsDivisionSales.divisionWiseSalesWeekly
		} else if (divisionWiseSalesCurrentTimeTab === 'monthly') {
			divisionWiseSalesData = salesAnalyticsDivisionSales.divisionWiseSalesMonthly
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 24 }}>
					<h3 style={{ marginBottom: 0 }}>Division Wise Sales</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsDivisionSales(divisionWiseSalesCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsDivisionSales}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDivisionWiseSalesCurrentTimeTab(tabItem.value)
										getSalesAnalyticsDivisionSales(tabItem.value)
									}}
									className={divisionWiseSalesCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									{tabItem.title}
								</div>
							)
						})}
					</div>
					<ResponsiveLine
						colors={['#298EA4']}
						data={[getDivisionWiseLineData(divisionWiseSalesData)]}
						indexBy='division'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendPosition: 'middle',
							legendOffset: 32,
							format: (d) => setDivisionWiseSalesAmountTimeRange(d)
						}}
						axisLeft={{
							tickSize: 5,
							tickPadding: 3,
							tickRotation: 0,
							legend: 'Sales x 10,000',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						pointSize={5}
						pointBorderWidth={10}
						pointColor={{ theme: 'background' }}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
						role='application'
						barAriaLabel={e => {
							return `${e.id} : ${e.formattedValue} in division: ${e.indexValue}`
						}}
					/>
				</div>
			</div>
		)
	}

	const renderDistributorWiseSalesChart = () => {
		let distributorWiseData = salesAnalyticsDistributorSales.distributorWiseSalesDaily
		if (distributorWiseSalesCurrentTimeTab === 'weekly') {
			distributorWiseData = salesAnalyticsDistributorSales.distributorWiseSalesWeekly
		} else if (distributorWiseSalesCurrentTimeTab === 'monthly') {
			distributorWiseData = salesAnalyticsDistributorSales.distributorWiseSalesYearly
		}

		return (
			<div className={styles.singleChartContainer} style={{ marginLeft: 24 }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 24 }}>
					<h3 style={{ marginBottom: 0 }}>Distributor Wise Sales</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsDistributorSales(distributorWiseSalesCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsDistributorSales}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setDistributorWiseSalesCurrentTimeTab(tabItem.value)
										getSalesAnalyticsDistributorSales(tabItem.value)
									}}
									className={distributorWiseSalesCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									{tabItem.title}
								</div>
							)
						})}
					</div>
					<ResponsiveLine
						colors={['#298EA4']}
						data={[getValueWiseLineData(distributorWiseData)]}
						indexBy='name'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						pointBorderColor={{ from: 'serieColor' }}
						pointSymbol={CustomSymbol}
						axisBottom={null}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 3,
							tickRotation: 0,
							legend: 'Sales x 10,000',
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						pointSize={5}
						pointBorderWidth={10}
						pointColor={{ theme: 'background' }}
						pointLabelYOffset={-12}
						useMesh={true}
					/>
				</div>
			</div>
		)
	}

	const renderItemWiseSalesChart = () => {
		let itemWiseData = salesAnalyticsItemSales.itemWiseSalesDaily
		if (itemWiseSalesCurrentTimeTab === 'weekly') {
			itemWiseData = salesAnalyticsItemSales.itemWiseSalesWeekly
		} else if (itemWiseSalesCurrentTimeTab === 'monthly') {
			itemWiseData = salesAnalyticsItemSales.itemWiseSalesYearly
		}

		return (
			<div className={styles.singleChartContainer}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, marginTop: 24 }}>
					<h3 style={{ marginBottom: 0 }}>Item Sales</h3>
					<Button
						style={{ marginLeft: 12, border: 'none' }}
						icon={<ReloadOutlined />}
						onClick={() => getSalesAnalyticsItemSales(itemWiseSalesCurrentTimeTab)}
						loading={isLoadingSalesAnalyticsItemSales}
					/>
				</div>
				<div className={styles.graphContainer}>
					<div className={styles.timeTabContainer}>
						{timeDifferentiateTabs.map(tabItem => {
							return (
								<div
									onClick={() => {
										setItemWiseSalesCurrentTimeTab(tabItem.value)
										getSalesAnalyticsItemSales(tabItem.value)
									}}
									className={itemWiseSalesCurrentTimeTab === tabItem.value ? styles.timeTabSelected : styles.timeTab} key={tabItem.value}
								>
									{tabItem.title}
								</div>
							)
						})}
					</div>
					<ResponsiveLine
						colors={['#298EA4']}
						data={[getValueWiseLineData(itemWiseData)]}
						indexBy='name'
						margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
						padding={0.3}
						axisTop={null}
						axisRight={null}
						pointBorderColor={{ from: 'serieColor' }}
						pointSymbol={CustomSymbol}
						axisBottom={null}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 3,
							tickRotation: 0,
							legend: `Items sales (${getCurrencyCode()})`,
							legendPosition: 'middle',
							legendOffset: -55
						}}
						theme={{
							axis: {
								legend: {
									text: {
										fill: '#64748B'
									}
								},
								fontSize: '12px'
							}
						}}
						pointSize={5}
						pointBorderWidth={10}
						pointColor={{ theme: 'background' }}
						pointLabelYOffset={-12}
						useMesh={true}
					/>
				</div>
			</div>
		)
	}

	const renderSalesAnalytics = () => {
		return (
			<div className={styles.data}>
				<div style={{ display: 'flex', width: '100%', marginTop: 60 }}>
					{renderSalesCountChart()}
					{renderSalesAmountChart()}
				</div>
				<div style={{ display: 'flex', width: '100%', marginTop: '48px' }}>
					{renderDistributorGrowthChart()}
					{renderDivisionWiseSalesChart()}
				</div>
				<div style={{ display: 'flex', width: '100%', marginTop: '48px' }}>
					{renderItemWiseSalesChart()}
					{renderDistributorWiseSalesChart()}
				</div>
			</div>
		)
	}

	const renderDeliveryPartnerAnalytics = () => {
		return (
			<div className={styles.data}>
				<div style={{ display: 'flex', width: '100%', marginTop: 60 }}>
					{renderOrdersAssignedAndCompletedRatioChart()}
					{renderDriverWiseCollectedAmountRatioChart()}
				</div>
				<div style={{ display: 'flex', width: '100%' }}>
					{renderDeliveryPartnerByLocationChart()}
					{renderDistanceTraveledByDeliveryPartnerChart()}
				</div>
			</div>
		)
	}

	const renderDashboardData = () => {
		if (currentTab === 'general_analytics') {
			return (
				<div className={styles.content}>
					<div className={styles.data}>
						<div style={{ display: 'flex', width: '100%', marginTop: 60 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
									<h3 style={{ marginBottom: 0 }}>Orders</h3>
									<Button
										style={{ marginLeft: 12, border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={() => getGeneralAnalyticsStatistics(analyticsTypes.orders)}
										loading={isLoadingGeneralAnalyticsStatistics || isOrdersLoading}
									/>
								</div>
								<div className={styles.statContainer}>
									<div style={{ display: 'flex', borderBottom: '1px solid #D9E0E9', flex: 1 }}>
										<div className={styles.stat} style={{ borderRight: '1px solid #D9E0E9' }}>
											<h2>Total</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{generalAnalyticsStatistics.orders.total.value}</div>
											</div>
										</div>
										<div className={styles.stat}>
											<h2>New</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{generalAnalyticsStatistics.orders.new.value}</div>
												{renderPercentage(generalAnalyticsStatistics.orders.total.percentageChange)}
											</div>
										</div>
									</div>
									<div style={{ display: 'flex', flex: 1 }}>
										<div className={styles.stat} style={{ borderRight: '1px solid #D9E0E9' }}>
											<h2>Open</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{generalAnalyticsStatistics.orders.open.value}</div>
												{renderPercentage(generalAnalyticsStatistics.orders.open.percentageChange)}
											</div>
										</div>
										<div className={styles.stat}>
											<h2>Cancelled</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{generalAnalyticsStatistics.orders.cancelled.value}</div>
												{renderPercentage(generalAnalyticsStatistics.orders.cancelled.percentageChange)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style={{ width: 24 }} />
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
									<h3 style={{ marginBottom: 0 }}>Sales</h3>
									<Button
										style={{ marginLeft: 12, border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={() => getGeneralAnalyticsStatistics(analyticsTypes.sales)}
										loading={isLoadingGeneralAnalyticsStatistics || isSalesLoading}
									/>
								</div>
								<div className={styles.statContainer}>
									<div style={{ display: 'flex', borderBottom: '1px solid #D9E0E9', flex: 1 }}>
										<div className={styles.stat} style={{ borderRight: '1px solid #D9E0E9' }}>
											<h2>Total</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{getFormattedCurrency(generalAnalyticsStatistics.sales.total.value)}</div>
											</div>
										</div>
										<div className={styles.stat}>
											<h2>New</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{getFormattedCurrency(generalAnalyticsStatistics.sales.new.value)}</div>
												{renderPercentage(generalAnalyticsStatistics.sales.total.percentageChange)}
											</div>
										</div>
									</div>
									<div style={{ display: 'flex', flex: 1 }}>
										<div className={styles.stat} style={{ borderRight: '1px solid #D9E0E9' }}>
											<h2>Open</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{getFormattedCurrency(generalAnalyticsStatistics.sales.open.value)}</div>
												{renderPercentage(generalAnalyticsStatistics.sales.open.percentageChange)}
											</div>
										</div>
										<div className={styles.stat}>
											<h2>Cancelled</h2>
											<div className={styles.statValueContainer}>
												<div style={{ marginRight: 12 }}>{getFormattedCurrency(generalAnalyticsStatistics.sales.cancelled.value)}</div>
												{renderPercentage(generalAnalyticsStatistics.sales.cancelled.percentageChange)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style={{ marginTop: 48 }}>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<div style={{ display: 'flex', width: '100%' }}>
										{renderOrderRatePercentageChart()}
										{renderOrderRateCycleTimeBarChart()}
									</div>
									<div style={{ display: 'flex', width: '100%' }}>
										{renderLeadTimeChart()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>)
		} else if (currentTab === 'sales_analytics') {
			return (
				<div className={styles.content}>
					{renderSalesAnalytics()}
				</div>
			)
		} else if (currentTab === 'delivery_partner_analytics') {
			return (
				<div className={styles.content}>
					{renderDeliveryPartnerAnalytics()}
				</div>
			)
		}
	}
	return (
		<div className={styles.container}>
			<div className={styles.contentHeader}>
				<div className={styles.contentHeaderContainer}>
					<div className={styles.tabContainer}>
						{
							mainTabs.map(tab => {
								return (
									<HeaderTab
										key={tab.value}
										title={tab.title}
										isSelected={() => currentTab === tab.value}
										onClick={() => {
											setCurrentTab(tab.value)
											switch (tab.value) {
												case tabs[1].value:
													getSalesAnalytics()
													break
												case tabs[2].value:
													getDeliveryPartnerAnalytics()
													break
												default:
													getGeneralAnalytics()
													break
											}
										}}
									/>
								)
							})
						}
					</div>
				</div>
			</div>
			{renderDashboardData()}
		</div>
	)
}

export default Dashboard
